import type firebase from 'firebase/app';

import type {
  FirebaseSafeRead,
  NarrowedDatabaseQuery as INarrowedDatabaseQuery,
  NarrowedDatabaseReference as INarrowedDatabaseReference,
  OverriddenDatabaseReferenceProperties,
} from '@lp-lib/firebase-typesafe';

export enum FirebaseStatus {
  None = 'None',
  Initializing = 'Initializing',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
}

export type RecoveryConfig = {
  intervalMs: number;
  maxAttempts: number;
  totalMs: number;
};

export type FirebaseEvents = {
  'connection-state-changed': (connected: boolean) => void;
};

// firebase.data.DataSnapshot is passed in to avoid needing the shared library
// to ever reference a real firebase package, such as firebase-functions or
// firebase/app. Multiple Firebase libraries do not seem to be compatible, even
// though at least DataSnapshot seems similar.

export interface NarrowedWebDatabaseReference<S>
  extends INarrowedDatabaseReference<S, firebase.database.DataSnapshot>,
    Omit<firebase.database.Reference, OverriddenDatabaseReferenceProperties> {}

export interface NarrowedWebDatabaseQuery<S>
  extends INarrowedDatabaseQuery<S, firebase.database.DataSnapshot>,
    Omit<firebase.database.Query, OverriddenDatabaseReferenceProperties> {}

export type NarrowedWebDatabaseQueryEventHandler<S> = Parameters<
  NarrowedWebDatabaseReference<S>['on']
>[1];

export type FirebaseSafeRef<T> = NarrowedWebDatabaseReference<
  FirebaseSafeRead<T>
>;

/**
 * .child() is currently not typed 100% correctly, in that it returns a
 * NarrowedDatabaseReference (e.g. the original typing) and not the generic
 * specified here, NarrowedWebDatabaseReference. Use this utility to rip the
 * inner type out of `.child()` and rewrap it with NarrowedWebDatabaseReference
 * so it's compatible with other utilities in the web-app.
 *
 * ```
 * const ref = prefixedSafeRef<T>('path/to/t');
 * // will be NarrowedDatabaseReference<T, snapshot>, not Web
 * const child = ref.child('safe/using/t');
 * // will now be NarrowedWebDatabaseReference<T>
 * const rewraped = child as FirebaseChildRewraper<typeof child>;
 * ```
 */
export type FirebaseWebRewraper<T> = T extends INarrowedDatabaseReference<
  infer Inner,
  firebase.database.DataSnapshot
>
  ? NarrowedWebDatabaseReference<Inner>
  : never;
