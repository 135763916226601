import { type RStatsSettings } from '../vendor/rstats/rstats';

// These will show up in the DevTools/Browser Performance Timeline, so it's
// useful to make them at least slightly readable in name.

// CONVENTIONs:
// - suffixed with `-c` (for "count") to indicate a value that doesn't need a
//   moving graph, just a number.
// - suffixed with `-ms` to indicate a value that is a duration in milliseconds.
// - prefixed with `committed-` to indicate a DOM/UI change by react
// - prefixed with `eval-` to indicate a hook or component evaluating (aka a
//   react render)
// - prefixed with `cache-miss-` to indicate some sort of memoized computation
//   miss, usually paired with `eval-`

export const RStatsCounterNames = [
  'frame',

  'paint-audience-ms',
  'paint-host-ms',

  'eval-cmp-audience-c',
  'eval-cmp-host-c',
  'gss-on-value-paint-ondState-ms',
  'gss-on-value-paint-controls-ms',
  'gss-on-value-paint-detailScores-ms',
  'gss-on-value-paint-scoreSummary-ms',
  'gss-on-value-paint-teamData-ms',
  'sentiment-recorder-poll-ms',
  'crowd-frame-anim-consumed-ms',
  'crowd-frame-anim-targets-c',
  'crowd-frame-accepted-ms',
  'crowd-frame-renderful-accepted-ms',
  'crowd-frame-renderless-accepted-ms',
  'crowd-frame-known-registers-c',
  'crowd-frame-payload-decoded-ms',
  'rstats-marks-measures-prune-ms',
  'rstats-perf-entries-c',
  'rstats-render-ms',
  'slow-events-delay-ms',
  'slow-events-processing-ms',
  'slow-events-duration-ms',
  'firebase-rtt-ms',
  'firebase-rtt-clock-drift-ms',
  'host-camera-mixer-draw-ms',
  'host-camera-mixer-tick-ms',
  'game-play-media-frame-ms',

  'cache-miss-select-participants-as-array-c',
  'cache-miss-select-participants-as-map-c',
  'cache-miss-select-teams-c',
  'cache-miss-select-team-c',
  'cache-miss-stage-member-v0-map-c',
  'cache-miss-townhall-teamview-useMembers-c',
  'cache-miss-use-select-team-members-by-team-id-c',
  'cache-miss-use-select-team-members-by-team-ids-c',
  'cache-miss-use-lobby-decision-data-c',

  'eval-select-participants-as-array-c',
  'eval-select-participants-as-map-c',
  'eval-select-participants-with-filters-c',
  'eval-select-teams-c',
  'eval-select-team-c',
  'eval-use-stage-member-v0-map-c',
  'eval-townhall-teamview-useMembers-c',
  'eval-use-select-team-members-by-team-id-c',
  'eval-use-select-team-members-by-team-ids-c',
  'eval-use-lobby-decision-data-c',

  'participant-add-c',
  'participant-update-c',
  'participant-remove-c',

  'task-queue-ai-chat-gameplayapi-ms',
  'task-queue-ai-chat-track-messages-ms',
  'task-queue-init-firebase-ms',
  'task-queue-init-game-session-ms',
  'task-queue-init-stage-ms',
  'task-queue-lobby-api-ms',
  'task-queue-music-device-control-ms',
  'task-queue-music-rtc-join-ms',
  'task-queue-pairing-game-ms',
  'task-queue-redux-init-ms',
  'task-queue-remote-stream-sub-audio-ms',
  'task-queue-remote-stream-sub-video-ms',
  'task-queue-rsb-submission-ms',
  'task-queue-rtc-broadcast-join-ms',
  'task-queue-rtc-game-join-ms',
  'task-queue-rtc-game-join-ms',
  'task-queue-rtc-ond-join-ms',
  'task-queue-rtc-ond-join-ms',
  'task-queue-rtc-stage-join-ms',
  'task-queue-rtc-stage-join-ms',
  'task-queue-rtc-localized-join-ms',
  'task-queue-townhall-api-ms',
  'task-queue-townhall-join-ms',
  'task-queue-townhall-switch-ms',
] as const;

export type RStatsCounterNamesT =
  | (typeof RStatsCounterNames)[number]
  | `bot-join-${string}-ms`
  // React committing to DOM followed by a paint
  | `paint-${string}-ms`
  // something was evaluated, like a react component
  | `eval-${string}-c`
  // something had a cache miss, such as a memoized result
  | `cache-miss-${string}-c`;

export function makeRStatsCounterConfig(
  isHostClient: boolean,
  userTimingEnabled: boolean
): RStatsSettings<RStatsCounterNamesT> {
  const config: RStatsSettings<RStatsCounterNamesT> = {
    userTimingAPI: userTimingEnabled,
    CSSPath: '',
    values: {
      frame: {
        caption: 'FPS',
        below: 56,
      },
      'sentiment-recorder-poll-ms': {
        caption: 'Sentiment/JoyCapture',
        over: 16,
      },

      'crowd-frame-accepted-ms': {
        caption: 'Payload Unpacked',
        over: 16,
      },
      'crowd-frame-renderful-accepted-ms': {
        caption: 'Loaded & Assigned (Renderful)',
        over: 100,
      },
      'crowd-frame-renderless-accepted-ms': {
        caption: 'Loaded & Assigned (Renderless)',
        over: 100,
      },
      'crowd-frame-anim-consumed-ms': {
        caption: 'Render',
        over: 16,
      },
      'crowd-frame-anim-targets-c': {
        caption: 'Render Anim Targets',
      },

      'rstats-render-ms': {
        caption: 'Stats Render',
      },

      'rstats-marks-measures-prune-ms': {
        caption: 'Stats Mark/Measure Prune',
      },

      'rstats-perf-entries-c': {
        caption: 'Stats Mark/Measure Count',
      },

      'slow-events-delay-ms': {
        caption: 'Delay',
      },

      'slow-events-processing-ms': {
        caption: 'Processing',
      },
      'slow-events-duration-ms': {
        caption: 'Total Duration',
      },

      'host-camera-mixer-draw-ms': {
        over: 16,
        average: true,
      },
      'host-camera-mixer-tick-ms': {
        over: 8,
        average: true,
      },

      'game-play-media-frame-ms': {
        average: true,
      },

      'firebase-rtt-ms': {
        average: true,
      },
      'firebase-rtt-clock-drift-ms': {
        average: true,
      },
    },
    groups: [
      {
        caption: 'Core',
        values: [
          'frame',
          'firebase-rtt-ms',
          'firebase-rtt-clock-drift-ms',
          'game-play-media-frame-ms',
        ],
      },
      {
        caption: 'Slow User Events (>100ms)',
        values: RStatsCounterNames.filter((n) => n.startsWith('slow-events-')),
      },
      {
        caption: 'Crowd Frames',
        values: RStatsCounterNames.filter((n) => n.startsWith('crowd-frame-')),
      },
      {
        caption: 'Sentiment/JoyCapture',
        values: ['sentiment-recorder-poll-ms'],
      },
      {
        caption: 'Firebase Game Refs',
        values: RStatsCounterNames.filter((n) => n.startsWith('gss-on-value-')),
      },
      {
        caption: 'Stats Meta',
        values: RStatsCounterNames.filter((n) => n.startsWith('rstats-')),
      },
      {
        caption: 'Task Queues',
        values: RStatsCounterNames.filter((n) => n.startsWith('task-queue')),
      },
      {
        caption: 'Paint Main Views (React -> Commit -> Paint)',
        values: RStatsCounterNames.filter((n) => n.startsWith('paint-')),
      },
      {
        caption: 'React Component / Hook Evaluations',
        values: RStatsCounterNames.filter((n) => n.startsWith('eval-')),
      },
      {
        caption: 'Cache Misses',
        values: RStatsCounterNames.filter((n) => n.startsWith('cache-miss-')),
      },
    ],
    // fractions: [
    //   {
    //     base: 'frame',
    //     steps: ['sentiment-recorder-poll'],
    //   },
    // ],
  };

  // Put convention-named counters here. They should order specific -> general.
  if (config.values)
    for (const n of RStatsCounterNames) {
      const existing = config.values[n];

      // committed- means react evaluated a change and committed it to the DOM.
      if (n.startsWith('committed-')) {
        config.values[n] = {
          ...existing,
          caption: n.replace('committed-', ''),
          over: 100,
        };
      }

      // Firebase Game Session Store stuff
      if (n.startsWith('gss-on-value')) {
        const caption = `${n.replace(
          'gss-on-value',
          ''
        )} (Recv -> Valtio Flush)`;
        config.values[n] = {
          ...existing,
          caption,
          over: 100,
        };
      }

      // Disable graphing to save resources/CPU for known count-only values.
      if (n.endsWith('-c')) {
        config.values[n] = { ...existing, graph: false };
      }

      // Automatically add an `(ms)` for readability
      if (n.endsWith('-ms')) {
        config.values[n] = {
          ...existing,
          caption: `${existing?.caption ?? n} (ms)`,
        };
      }
    }

  if (isHostClient)
    config.groups?.push({
      caption: 'Host',
      values: ['host-camera-mixer-draw-ms', 'host-camera-mixer-tick-ms'],
    });

  return config;
}
