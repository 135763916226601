export class FirebaseRefNotInitializedError extends Error {
  name = 'FirebaseRefNotInitializedError';
  constructor(ref: string) {
    super(`Firebase ref '${ref}' is not initialized`);
    Object.setPrototypeOf(this, FirebaseRefNotInitializedError.prototype);
  }
}

export class UnexpectedError extends Error {
  name = 'UnexpectedError';
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, FirebaseRefNotInitializedError.prototype);
  }
}

export class SliceNotInitializedError extends Error {
  name = 'SliceNotInitializedError';
  constructor(sliceName: string) {
    super(`Slice '${sliceName}' is not initialized`);
    Object.setPrototypeOf(this, SliceNotInitializedError.prototype);
  }
}
