/* eslint-disable @lp-lib/eslint-rules/encapsulated-redux */
import 'firebase/database';

import { type PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import type firebase from 'firebase/app';
import sample from 'lodash/sample';
import { batch } from 'react-redux';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import config from '../../config';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { createAppSlice } from '../../store/createSlice';
import { UnexpectedError } from '../../store/exception';
import { type AppThunk, type RootState } from '../../store/types';
import { FBPathUtils, isTimeout } from '../../store/utils';
import {
  type MemberId,
  type Team,
  TEAM_COLORS,
  type TeamId,
  type TeamMember,
} from '../../types/team';
import { type TownhallMode } from '../../types/townhall';
import {
  type AwayMessage,
  type Participant,
  type ParticipantFull,
  type ParticipantMap,
} from '../../types/user';
import { randomPick, randomString, required } from '../../utils/common';
import { type IStorage, StorageFactory } from '../../utils/storage';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { firebaseService } from '../Firebase';
// eslint-disable-next-line no-restricted-imports
import { isActiveParticipant } from '../Player/participantSlice';

const log = logger.scoped('teamSlice');

type MemberMap = Record<MemberId, TeamMember>;

interface TeamState {
  venueId: string | null;
  teams: Record<TeamId, Team | undefined>;
  teamMembers: Record<TeamId, MemberMap | undefined>;
  showTeamIsFullModel: boolean;
  recovering: boolean;
  recovered: boolean;
  maxTeamMembers: number;
  joinLock: Record<MemberId, boolean>;
}

export interface CreateTeamPayload {
  memberId?: MemberId;
  username?: string;
  teamName?: string;
  teamColor?: string;
  cohost: boolean | undefined;
  debug?: string;
  updateParticipant?: (
    clientId: string,
    data: Partial<ParticipantFull>
  ) => Promise<void>;
}

interface AddTeamMemberPayload {
  teamId: TeamId;
  member: TeamMember;
}

interface RemoveTeamMemberPayload {
  teamId: TeamId;
  memberId: MemberId;
}

interface UpdateTeamNamePayload {
  teamId: TeamId;
  teamName: string;
}

interface LocalTeamInfo {
  teamId: TeamId;
  memberId: MemberId;
}

export interface JoinTeamPayload {
  memberId: MemberId;
  teamId: TeamId;
  updateParticipant: (
    clientId: string,
    data: Partial<ParticipantFull>
  ) => Promise<void>;
  force?: boolean;
  debug?: string;
  skipUpdateLocalStorage?: boolean;
  maxTeamSize?: number;
}

export interface LeaveTeamPayload {
  memberId: MemberId;
  teamId: TeamId;
  markAsAway?: AwayMessage;
  debug?: string;
}

class LocalTeamStorage {
  private static key = 'myJoinedTeam';
  private storage: IStorage<string, Record<string, LocalTeamInfo>>;
  constructor(private venueId: string) {
    this.storage = StorageFactory<string, Record<string, LocalTeamInfo>>(
      'local'
    );
  }
  joinTeam(teamInfo: LocalTeamInfo) {
    const data = this.storage.get(LocalTeamStorage.key) || {};
    data[this.venueId] = teamInfo;
    this.storage.set(LocalTeamStorage.key, data);
  }
  leaveTeam() {
    const data = this.storage.get(LocalTeamStorage.key) || {};
    delete data[this.venueId];
    this.storage.set(LocalTeamStorage.key, data);
  }
  get(): LocalTeamInfo | null {
    const data = this.storage.get(LocalTeamStorage.key) || {};
    return data[this.venueId] ?? null;
  }
}

type TeamSliceStore = {
  teamListRef: firebase.database.Reference;
  teamMemberMapRef: firebase.database.Reference;
  venueId: string;
  localTeamStorage: LocalTeamStorage;
  getParticipants: () => ParticipantMap;
};

export class TeamStoreUtils {
  private static store: TeamSliceStore | null = null;
  static Init(_store: TeamSliceStore): void {
    TeamStoreUtils.store = _store;
  }
  static Get(): TeamSliceStore {
    return required(TeamStoreUtils.store, 'teamSliceStore');
  }

  /**
   * BE CAREFUL WITH THIS. It is a "pull" API, meaning the data can become stale
   * if you do something like this:
   *
   * ```
   * const id = geIdFromSomewhere();
   * const participants = TeamStoreUtils.getParticipants();
   * const p = participants[id];
   * if (p.status === 'connected') await something();
   * p.status // this could have changed during the `await`
   */
  static GetParticipants(): { [clientId: string]: Participant | undefined } {
    if (!TeamStoreUtils.store) {
      return {};
    }
    return TeamStoreUtils.store.getParticipants();
  }

  static Dispose(): void {
    TeamStoreUtils.store = null;
  }
}

const initialState: TeamState = {
  venueId: null,
  teams: {},
  teamMembers: {},

  showTeamIsFullModel: false,
  recovering: false,
  recovered: false,
  maxTeamMembers: 0,
  joinLock: {},
};

const batching = getFeatureQueryParam('redux-auto-batching');

export const teamSlice = createAppSlice({
  name: 'team',
  initialState,

  reducers: (create) => ({
    setVenueId: create.reducer((state, action: PayloadAction<string>) => {
      state.venueId = action.payload;
    }),

    setTeams: create.reducer(
      (state, action: PayloadAction<Record<TeamId, Team>>) => {
        state.teams = action.payload;
      }
    ),

    setTeamMembers: create.reducer(
      (
        state,
        action: PayloadAction<Record<TeamId, Record<MemberId, TeamMember>>>
      ) => {
        state.teamMembers = action.payload;
      }
    ),

    updateTeamMembers: create.preparedReducer(
      batching
        ? prepareAutoBatched<Record<TeamId, Record<MemberId, TeamMember>>>()
        : (payload: Record<TeamId, Record<MemberId, TeamMember>>) => ({
            payload,
          }),
      (state, action) => {
        for (const [teamId, members] of Object.entries(action.payload)) {
          state.teamMembers[teamId] = members;
        }
      }
    ),

    addTeam: create.reducer((state, action: PayloadAction<Team>) => {
      state.teams[action.payload.id] = action.payload;
    }),

    removeTeam: create.reducer((state, action: PayloadAction<TeamId>) => {
      delete state.teams[action.payload];
    }),

    addTeamMember: create.reducer(
      (state, action: PayloadAction<AddTeamMemberPayload>) => {
        state.teamMembers[action.payload.teamId] = Object.assign(
          state.teamMembers[action.payload.teamId] ?? {},
          {
            [action.payload.member.id]: action.payload.member,
          }
        );
      }
    ),

    removeTeamMember: create.reducer(
      (state, action: PayloadAction<RemoveTeamMemberPayload>) => {
        const members = state.teamMembers[action.payload.teamId];
        if (members) {
          delete members[action.payload.memberId];
        }
      }
    ),

    removeTeamMembersByTeamIds: create.preparedReducer(
      batching
        ? prepareAutoBatched<TeamId[]>()
        : (payload: TeamId[]) => ({ payload }),
      (state, action) => {
        for (const teamId of action.payload) {
          delete state.teamMembers[teamId];
        }
      }
    ),

    updateTeamName: create.reducer(
      (state, action: PayloadAction<UpdateTeamNamePayload>) => {
        const team = state.teams[action.payload.teamId];
        if (team) {
          state.teams[action.payload.teamId] = {
            ...team,
            name: action.payload.teamName,
          };
        }
      }
    ),

    setShowTeamIsFullModel: create.reducer(
      (state, action: PayloadAction<boolean>) => {
        state.showTeamIsFullModel = action.payload;
      }
    ),

    setRecovering: create.reducer((state, action: PayloadAction<boolean>) => {
      state.recovering = action.payload;
    }),

    setRecovered: create.reducer((state, action: PayloadAction<boolean>) => {
      state.recovered = action.payload;
    }),

    setMaxTeamMembers: create.reducer(
      (state, action: PayloadAction<number>) => {
        state.maxTeamMembers = action.payload;
      }
    ),

    setJoinLock: create.reducer(
      (
        state,
        action: PayloadAction<{ memberId: MemberId; locked: boolean }>
      ) => {
        if (action.payload.locked) {
          state.joinLock[action.payload.memberId] = true;
        } else {
          delete state.joinLock[action.payload.memberId];
        }
      }
    ),

    setTeamTownhallMode: create.reducer(
      (
        state,
        action: PayloadAction<{ teamId: TeamId; mode: TownhallMode }>
      ) => {
        const team = state.teams[action.payload.teamId];
        if (team) {
          state.teams[action.payload.teamId] = {
            ...team,
            townhallMode: action.payload.mode,
          };
        }
      }
    ),

    reset: create.reducer((state) => {
      Object.keys(initialState).forEach((key) => {
        uncheckedIndexAccess_UNSAFE(state)[key] =
          uncheckedIndexAccess_UNSAFE(initialState)[key];
      });
    }),
  }),
});

export const { setShowTeamIsFullModel, setMaxTeamMembers } = teamSlice.actions;

const {
  setVenueId,
  setTeams,
  setTeamMembers,
  updateTeamMembers,
  addTeam,
  removeTeam,
  addTeamMember,
  removeTeamMember,
  removeTeamMembersByTeamIds,
  updateTeamName,
  setJoinLock,
  setTeamTownhallMode,
  reset,
} = teamSlice.actions;

async function initTeamListRef(
  dispatch: Parameters<AppThunk>[0],
  venueId: string
): Promise<[firebase.database.Reference, () => void]> {
  const teamListRef = firebaseService
    .database()
    .ref(FBPathUtils.ForTeams(firebaseService, venueId));
  const snapshot = await teamListRef.get();
  const data = snapshot.val();
  if (data) {
    dispatch(setTeams(data));
  }
  teamListRef.on('child_added', (snapshot) => {
    const data = snapshot.val();
    log.debug('teams child_added', { team: data });
    dispatch(addTeam(data));
  });
  teamListRef.on('child_changed', (snapshot) => {
    const data = snapshot.val();
    log.debug('teams child_changed', { team: data });
    dispatch(addTeam(data));
  });
  teamListRef.on('child_removed', (snapshot) => {
    const data = snapshot.val();
    log.debug('teams child_removed', { team: data });
    dispatch(removeTeam(data.id));
  });
  return [
    teamListRef,
    () => {
      teamListRef?.off();
    },
  ];
}

async function initTeamMemberMapRef(
  dispatch: Parameters<AppThunk>[0],
  venueId: string
): Promise<[firebase.database.Reference, () => void]> {
  const teamMemberMapRef = firebaseService
    .database()
    .ref(FBPathUtils.ForTeamMembers(firebaseService, venueId));
  const snapshot = await teamMemberMapRef.get();
  const data = snapshot.val();
  if (data) {
    dispatch(setTeamMembers(data));
  }

  const queuedActions: Parameters<typeof dispatch>[0][] = [];

  const queueFlushIntervalId = setInterval(() => {
    if (queuedActions.length > 0) {
      log.debug('queue length', { length: queuedActions.length });
    }
    if (queuedActions.length > 0) {
      batch(() => {
        for (const action of queuedActions) {
          dispatch(action);
        }
      });
      queuedActions.length = 0;
    }
  }, 200);

  teamMemberMapRef.on('child_added', (snapshot) => {
    const data = snapshot.val();
    log.debug('team-members child_added', {
      teamId: snapshot.key,
      teamMembers: data,
    });
    if (!snapshot.key) {
      throw new UnexpectedError('key should not be null');
    }
    queuedActions.push(updateTeamMembers({ [snapshot.key]: data }));
  });
  teamMemberMapRef.on('child_changed', (snapshot) => {
    const data = snapshot.val();
    log.debug('team-members child_changed', {
      teamId: snapshot.key,
      teamMembers: data,
    });
    if (!snapshot.key) {
      throw new UnexpectedError('key should not be null');
    }
    queuedActions.push(updateTeamMembers({ [snapshot.key]: data }));
  });
  teamMemberMapRef.on('child_removed', (snapshot) => {
    const data = snapshot.val();
    log.debug('team-members child_removed', {
      teamId: snapshot.key,
      teamMembers: data,
    });
    if (!snapshot.key) {
      throw new UnexpectedError('key should not be null');
    }
    queuedActions.push(removeTeamMembersByTeamIds([snapshot.key]));
  });

  return [
    teamMemberMapRef,
    () => {
      teamMemberMapRef?.off();
      clearInterval(queueFlushIntervalId);
    },
  ];
}

export const isActiveMember = (
  teamId: Team['id'],
  m: TeamMember | null,
  participants: ParticipantMap
): boolean =>
  !!m?.id &&
  participants[m.id]?.status === ConnectionStatus.Connected &&
  participants[m.id]?.teamId === teamId;

export const filterActiveMembers = (
  teamId: Team['id'],
  teamMembers: Record<MemberId, TeamMember> | null | undefined,
  participants: ParticipantMap
): TeamMember[] => {
  return teamMembers
    ? Object.values(teamMembers).filter((m) =>
        isActiveMember(teamId, m, participants)
      )
    : [];
};

function makeTeamIds(state: TeamState) {
  return Object.keys(state.teams);
}

function selectTeam(state: TeamState, teamId: Nullable<Team['id']>) {
  return teamId ? state.teams[teamId] : null ?? null;
}

export function selectTeamMember(
  state: TeamState,
  teamId: Team['id'],
  clientId: Participant['clientId']
) {
  return state.teamMembers[teamId]?.[clientId] ?? null;
}

export function selectTeamMembers(
  state: TeamState,
  teamId: Nullable<Team['id']>
) {
  return teamId ? state.teamMembers[teamId] : null;
}

export function selectTeamCaptainClientId(
  state: TeamState,
  teamId: Nullable<Team['id']>
) {
  return teamId ? state.teams[teamId]?.captainScribe : null;
}

function selectActiveTeamMembersCount(
  state: TeamState,
  teamId: Nullable<Team['id']>,
  participants: ParticipantMap
) {
  const teamMembers = selectTeamMembers(state, teamId);
  if (!teamMembers || !teamId) return 0;
  return selectActiveTeamMembers(state, teamId, participants)?.length ?? 0;
}

export function selectActiveTeamMembers(
  state: TeamState,
  teamId: Nullable<Team['id']>,
  participants: ParticipantMap
) {
  if (!teamId) return null;
  const teamMembers = selectTeamMembers(state, teamId);
  return filterActiveMembers(teamId, teamMembers, participants);
}

function selectNextTeamCaptain(
  state: TeamState,
  teamId: Nullable<Team['id']>,
  participants: ParticipantMap
) {
  if (!teamId) return null;
  const members = selectActiveTeamMembers(state, teamId, participants);
  const oldCaptain = selectTeamCaptainClientId(state, teamId);
  if (members?.find((m) => m.id === oldCaptain)) return oldCaptain;
  if (members?.length === 0) return null;

  // no scribe, confer to oldest

  members?.sort((a, b) => a.joinedAt - b.joinedAt);
  const oldest = members?.[0];
  if (!oldest) return null;
  return oldest.id;
}

const getNextColor = (
  state: RootState,
  participants: ParticipantMap
): string => {
  const teams = makeTeamIds(state.team);
  const used = new Set();
  for (let i = 0; i < teams.length; i++) {
    const team = selectTeam(state.team, teams[i]);
    const count = selectActiveTeamMembersCount(
      state.team,
      team?.id,
      participants
    );
    if (count > 0) used.add(team?.color);
  }

  const allColors = TEAM_COLORS;
  for (let i = 0; i < allColors.length; i++) {
    if (!used.has(allColors[i])) return allColors[i];
  }

  return randomPick(allColors);
};

export const init =
  (
    venueId: string,
    getParticipants: () => ParticipantMap
  ): AppThunk<Promise<() => void>> =>
  async (dispatch) => {
    const [teamListRef, disposeTeamListRef] = await initTeamListRef(
      dispatch,
      venueId
    );
    const [teamMemberMapRef, disposeTeamMemberMapRef] =
      await initTeamMemberMapRef(dispatch, venueId);

    TeamStoreUtils.Init({
      teamListRef,
      teamMemberMapRef,
      venueId,
      localTeamStorage: new LocalTeamStorage(venueId),
      getParticipants,
    });
    // note: setting the venue id dictates the store being ready. call Init first.
    dispatch(setVenueId(venueId));
    log.info('inited');

    return () => {
      disposeTeamListRef();
      disposeTeamMemberMapRef();
      dispatch(reset());
      TeamStoreUtils.Dispose();
      log.info('disposed');
    };
  };

export const joinTeam =
  ({
    teamId,
    memberId,
    force = false,
    debug,
    skipUpdateLocalStorage,
    maxTeamSize,
    updateParticipant,
  }: JoinTeamPayload): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const store = TeamStoreUtils.Get();
    const state = getState();
    if (state.team.joinLock[memberId]) {
      log.warn('join team locked', { memberId, targetTeamId: teamId, debug });
      return;
    }
    try {
      await dispatch(setJoinLock({ memberId, locked: true }));
      if (!state.team.teams[teamId]) {
        log.warn('the member tried to join a non-exist team', {
          teamId,
          memberId,
          debug,
        });
        return;
      }
      const participants = TeamStoreUtils.GetParticipants();
      const participant = participants[memberId];
      if (!participant) {
        log.warn('participant not found', { teamId, memberId, debug });
        return;
      }

      if (!force && participant.teamId === teamId) {
        // when the firebase gets disconnected, the remove changes
        // can not be synced in time. And the auto recovery might
        // be trigger before the synchronization. Add `force` to
        // skip this check.
        log.warn('the member has already joined this team', {
          teamId,
          memberId,
          debug,
        });
        return;
      }

      // TODO(drew): this operation of filtering and checking should happen in a
      // firebase transaction to avoid race conditions.

      const snap = await store.teamMemberMapRef.child(teamId).get();
      const activeMembers = filterActiveMembers(
        teamId,
        snap.val(),
        participants
      );
      const teamSizeLimit = maxTeamSize ?? state.team.maxTeamMembers;
      if (teamSizeLimit > 0 && activeMembers.length >= teamSizeLimit) {
        log.warn('the team is full', {
          teamId: teamId,
          debug,
          activeMembersCount: activeMembers.length,
          teamSizeLimit: {
            maxTeamMembersFromState: state.team.maxTeamMembers,
            maxTeamSizeFromPayload: maxTeamSize,
            resolvedLimit: teamSizeLimit,
          },
        });
        return;
      }

      const updates = uncheckedIndexAccess_UNSAFE({});
      const actions: Parameters<typeof dispatch>[0][] = [];

      const oldTeam: {
        teamId: Nullable<string>;
        needsNewCaptain: boolean;
        captainId: Nullable<string>;
      } = {
        teamId: participant.teamId,
        needsNewCaptain: false,
        captainId: null,
      };
      if (oldTeam.teamId) {
        // leave old team
        updates[
          FBPathUtils.ForTeamMembers(firebaseService, store.venueId, {
            teamId: oldTeam.teamId,
            memberId: participant.clientId,
          })
        ] = null;

        const oldCaptainClientId = selectTeamCaptainClientId(
          state.team,
          oldTeam.teamId
        );
        oldTeam.captainId = oldCaptainClientId ?? null;

        oldTeam.needsNewCaptain =
          !!oldCaptainClientId &&
          state.team.teams[oldTeam.teamId]?.captainScribe ===
            participant.clientId;

        actions.push(
          removeTeamMember({
            teamId: oldTeam.teamId,
            memberId: participant.clientId,
          })
        );
      }

      log.info('old team', {
        oldTeam,
        debug,
      });

      const teamMember: TeamMember = {
        id: memberId,
        joinedAt: Date.now(),
      };

      // join new team
      updates[
        FBPathUtils.ForTeamMembers(firebaseService, store.venueId, {
          teamId: teamId,
          memberId: participant.clientId,
        })
      ] = teamMember;

      // update teamId on participant
      updates[
        `${FBPathUtils.ForParticipants(
          firebaseService,
          store.venueId,
          participant.clientId
        )}/teamId`
      ] = teamId;
      // reset the away message
      updates[
        `${FBPathUtils.ForParticipants(
          firebaseService,
          store.venueId,
          participant.clientId
        )}/away`
      ] = null;

      await updateParticipant(participant.clientId, { teamId });

      actions.push(
        addTeamMember({
          teamId: teamId,
          member: teamMember,
        })
      );

      log.info('join team, update firebase', { updates, debug });

      const ref = firebaseService.database().ref();
      await ref.update(updates);

      batch(() => {
        actions.forEach((action) => dispatch(action));
      });

      let captainId = selectTeamCaptainClientId(state.team, teamId);
      if (!captainId) {
        captainId = await dispatch(
          assignNewTeamCaptainScribe(teamId, memberId)
        );
      }
      if (oldTeam.needsNewCaptain && oldTeam.teamId) {
        oldTeam.captainId = await dispatch(
          assignNewTeamCaptainScribe(oldTeam.teamId)
        );
      }

      log.info('join team done', {
        new: {
          teamId: teamId,
          memberId: memberId,
          captainId,
        },
        old: oldTeam,
        debug,
      });

      if (!skipUpdateLocalStorage) {
        store.localTeamStorage.joinTeam({
          teamId: teamId,
          memberId: memberId,
        });
      }
    } catch (error) {
      throw error;
    } finally {
      await dispatch(setJoinLock({ memberId, locked: false }));
    }
  };

export const leaveTeam =
  ({
    teamId,
    memberId,
    debug,
    markAsAway,
  }: LeaveTeamPayload): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const store = TeamStoreUtils.Get();
    const state = getState();

    if (!state.team.teams[teamId]) {
      log.warn('the member tried to leave a non-existent team', {
        teamId: teamId,
        memberId: memberId,
        debug,
      });
      return;
    }

    if (!selectTeamMember(state.team, teamId, memberId)) {
      log.warn('team member not found', { teamId, memberId, debug });
      return;
    }

    // note: it's possible we are forcing another client id to leave the team. in that case we don't want to
    // evict our own team assignment in local storage.
    if (state.participant.myClientId === memberId) {
      store.localTeamStorage.leaveTeam();
    }

    const updates: { [key: string]: unknown } = {
      [FBPathUtils.ForTeamMembers(firebaseService, store.venueId, {
        teamId,
        memberId,
      })]: null,
      [`${FBPathUtils.ForParticipants(
        firebaseService,
        store.venueId,
        memberId
      )}/teamId`]: null,
    };

    if (markAsAway) {
      updates[
        `${FBPathUtils.ForParticipants(
          firebaseService,
          store.venueId,
          memberId
        )}/away`
      ] = markAsAway;
    }

    log.info('leave team, update firebase', { updates, debug });

    const ref = firebaseService.database().ref();
    await ref.update(updates);

    await dispatch(
      removeTeamMember({
        teamId: teamId,
        memberId: memberId,
      })
    );

    log.info('leave team done', { teamId: teamId, memberId: memberId, debug });

    await dispatch(ensureLeaveAllParticipantsWithSameUidFromTeams(memberId));
  };

export const createTeam =
  (payload: CreateTeamPayload): AppThunk<Promise<Team>> =>
  async (dispatch, getState) => {
    const store = TeamStoreUtils.Get();
    const state = getState();

    let idx = 0;
    const baseTeamId = Date.now().toString();
    let teamId = baseTeamId;

    // Prevent collisions by using an incrementing suffix (e.g. "machine
    // sequence number"). TeamIds are used in the agora channel name, which has
    // a fixed length limit so we cannot use UUIDs.
    while (true) {
      const existing = !!state.team.teams[teamId];
      if (existing) teamId = `${baseTeamId}-${idx++}`;
      else break;
    }

    let name = payload?.teamName;
    if (payload?.memberId) {
      if (!name) name = `${payload.username}'s Team`;
    }
    if (!name) name = `Team ${randomString(3)}`;
    let color = payload?.teamColor;
    if (!color) color = getNextColor(state, TeamStoreUtils.GetParticipants());

    if (payload.cohost) name = `Cohost Team`;

    const team: Team = {
      id: teamId,
      name: name,
      color: color,
      createdAt: Date.now(),
      captainScribe: null,
      isCohostTeam: payload?.cohost ?? false,
    };
    await dispatch(addTeam(team));
    const newTeamRef = store.teamListRef.child(team.id);
    await newTeamRef.set(team);
    log.info('create team', { team, debug: payload?.debug });
    if (payload?.memberId) {
      if (!payload.updateParticipant)
        throw new Error(
          'updateParticipant is required when memberId is provided'
        );
      await dispatch(
        joinTeam({
          teamId,
          memberId: payload.memberId,
          debug: payload.debug,
          updateParticipant: payload.updateParticipant,
        })
      );
    }
    return team;
  };

export const renameTeam =
  (teamId: string, teamName: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const store = TeamStoreUtils.Get();
    dispatch(updateTeamName({ teamId, teamName }));
    const newTeamRef = store.teamListRef.child(teamId);
    await newTeamRef.update({ name: teamName });
  };

const sync = (): AppThunk<Promise<void>> => async (dispatch) => {
  const store = TeamStoreUtils.Get();
  const teamListSnapshot = await store.teamListRef.get();
  const teams = teamListSnapshot.val();
  if (teams) dispatch(setTeams(teams));

  const teamMemberMapSnapshot = await store.teamMemberMapRef.get();
  const teamMembers = teamMemberMapSnapshot.val();
  if (teamMembers) dispatch(setTeamMembers(teamMembers));
};

export const tryTeamRecovery =
  (
    clientId: string,
    skip: boolean,
    syncParticipants: () => Promise<void>,
    updateParticipant: (
      clientId: string,
      data: Partial<ParticipantFull>
    ) => Promise<void>
  ): AppThunk =>
  async (dispatch, getState) => {
    const store = TeamStoreUtils.Get();
    const state = getState();

    // this is only used for staff users, previously we don't event call this
    // function so that the _recovered_ is never set. Which stopped auto
    // join/create team to be working properly.
    if (skip) {
      log.info('skip team recovery');
      await dispatch(teamSlice.actions.setRecovered(true));
      return;
    }

    if (state.team.recovering) {
      log.info('team recovery in progress');
      return;
    }

    await dispatch(teamSlice.actions.setRecovering(true));

    try {
      // force syncing from the firebase in case the local state is obsolete
      await syncParticipants();
      await dispatch(sync());

      const localTeamInfo = store.localTeamStorage.get();
      if (!localTeamInfo) return;
      const team = state.team.teams[localTeamInfo.teamId];

      // Never try to rejoin a cohost team. The user might no longer be a
      // cohost!
      if (team?.isCohostTeam) return;

      const participants = TeamStoreUtils.GetParticipants();
      const participant = participants[localTeamInfo.memberId];
      const membersCount = selectActiveTeamMembersCount(
        state.team,
        localTeamInfo.teamId,
        participants
      );

      // Attempt to handle the case where a remote team assignment was not
      // received before you went offline. An example would be the randomizer
      // executing as you experience a disconnection.
      let targetTeam = team?.id;
      if (
        team &&
        participant?.teamId &&
        participant?.teamId !== localTeamInfo.teamId
      ) {
        targetTeam = participant.teamId;
        log.info(
          'team auto recovery local team assignment does not match remote, using remote',
          {
            localTeamId: team?.id,
            remoteTeamId: participant?.teamId,
            targetTeam,
          }
        );
      }

      // NOTE: we do not check maxTeamMembers here, because the user is likely
      // coming back after a refresh or being marked as away. Even if someone
      // took their slot while refreshing or away, it's probably better to have
      // a slightly larger team than for them to completely lose their spot.
      const canRejoin =
        !!team &&
        !!participant &&
        !!targetTeam &&
        !isTimeout(participant, config.team.autoRejoinTimeout);

      log.info('team auto recovery attempt', {
        teamId: targetTeam,
        status: participant?.status,
        disconnectedAt: participant?.disconnectedAt,
        membersCount,
        canRejoin,
      });
      if (canRejoin && targetTeam) {
        await dispatch(
          joinTeam({
            teamId: targetTeam,
            memberId: clientId,
            force: true,
            debug: 'team-recovery',
            updateParticipant,
          })
        );
        log.info('team auto recovery successful', {
          teamId: targetTeam,
          clientId,
        });
      } else if (targetTeam) {
        if (membersCount >= state.team.maxTeamMembers) {
          dispatch(setShowTeamIsFullModel(true));
        }
        log.info('team auto recovery failed, cleanup', {
          teamId: targetTeam,
          clientId,
        });
        await dispatch(
          leaveTeam({
            teamId: targetTeam,
            memberId: localTeamInfo.memberId,
          })
        );
      }
    } catch (error) {
      log.error('team auto recovery failed', error);
    } finally {
      await dispatch(teamSlice.actions.setRecovering(false));
      await dispatch(teamSlice.actions.setRecovered(true));
    }
  };

export const assignNewTeamCaptainScribe =
  (
    teamId: TeamId,
    memberId: MemberId | null = null
  ): AppThunk<Promise<string | null>> =>
  async (_, getState) => {
    const store = TeamStoreUtils.Get();
    const state = getState();

    const nextCaptainId =
      memberId ??
      selectNextTeamCaptain(
        state.team,
        teamId,
        TeamStoreUtils.GetParticipants()
      );

    if (!nextCaptainId) {
      log.info('no next captain found', { teamId, nextCaptainId, memberId });
      return null;
    }

    log.info('setting next captain', { teamId, nextCaptainId });

    // Do not set captainScribe locally, it will be set by firebase `onUpdate` -> redux.
    const teamRef = store.teamListRef.child(teamId);
    await teamRef.update({ captainScribe: nextCaptainId });
    log.info('finished setting next captain', { teamId, nextCaptainId });
    return nextCaptainId;
  };

export const cycleTeamCaptainScribes =
  (): AppThunk<Promise<void>> => async (_, getState) => {
    const store = TeamStoreUtils.Get();
    const state = getState();
    const participants = TeamStoreUtils.GetParticipants();

    const updates = uncheckedIndexAccess_UNSAFE({});
    for (const teamId of Object.keys(state.team.teams)) {
      const members = selectActiveTeamMembers(state.team, teamId, participants);
      if (!members || members.length === 0) continue;

      // oldest to newest
      members.sort((a, b) => a.joinedAt - b.joinedAt);

      const oldCaptain = selectTeamCaptainClientId(state.team, teamId);
      const oldCaptainIndex = members.findIndex((m) => m.id === oldCaptain);

      let nextCaptainId: string | undefined;
      if (oldCaptainIndex === -1) {
        log.warn('old captain not found', { teamId, oldCaptain });
        nextCaptainId = sample(members)?.id;
      } else {
        const nextCaptainIndex = (oldCaptainIndex + 1) % members.length;
        nextCaptainId = members[nextCaptainIndex].id;
      }

      if (!nextCaptainId) continue;

      updates[`${teamId}/captainScribe`] = nextCaptainId;
    }

    log.info('setting next captains', updates);
    await store.teamListRef.update(updates);
  };

const ensureLeaveAllParticipantsWithSameUidFromTeams =
  (clientId: string): AppThunk<Promise<void>> =>
  async (_, getState) => {
    const state = getState();
    const participant = TeamStoreUtils.GetParticipants()[clientId];
    if (!participant) {
      log.info('participant not found', { clientId });
      return;
    }

    const participants = Object.values(TeamStoreUtils.GetParticipants()).filter(
      (p): p is Participant => !!p && p.id === participant.id
    );

    const members: TeamMember[] = [];
    for (const p of participants) {
      const memberMaps = Object.values(state.team.teamMembers);
      for (const map of memberMaps) {
        const member = map?.[p.clientId];
        if (member) members.push(member);
      }
    }

    // Note(jialin): log only, it can be enabled/disabled later based on the log insight
    if (members.length > 0) {
      log.info('members to be removed with same uid', {
        members,
        clientId,
        uid: participant.id,
      });
    }
  };

export const switchTeamTownhallMode =
  (teamId: TeamId, mode: TownhallMode): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const store = TeamStoreUtils.Get();

    dispatch(setTeamTownhallMode({ teamId, mode }));
    const teamRef = store.teamListRef.child(teamId);
    await teamRef.update({ townhallMode: mode });
  };

// multiple calls, half as appSelector, half as getter/callback
export const selectTeamMembersByTeamId = (
  state: TeamState,
  teamId: Nullable<string>,
  participants: ParticipantMap
): TeamMember[] => {
  const members = selectActiveTeamMembers(state, teamId, participants);
  if (!members) return [];

  return members;
};

export const selectIsTeamStoreInited = (state: RootState): boolean => {
  return !!state.team.venueId;
};

export const selectShowTeamIsFullModel = (state: RootState): boolean =>
  state.team.showTeamIsFullModel;

export const selectIsTeamRecoveryRunning = (state: RootState): boolean =>
  !!state.team.recovering;

export const selectIsTeamRecovered = (state: RootState): boolean =>
  state.team.recovered;

export const selectTeamCaptainScribe = (
  state: RootState,
  participants: ParticipantMap,
  teamId: TeamId | null | undefined,
  includeInactive?: boolean
): null | TeamMember => {
  if (!teamId) return null;
  const team = state.team.teams[teamId];
  if (!team?.captainScribe) return null;
  const member = state.team.teamMembers[teamId]?.[team?.captainScribe];
  if (!member) return null;
  if (includeInactive) return member;
  const participant = participants[member.id];
  if (!participant) return null;
  return isActiveParticipant(participant) ? member : null;
};

export const teamSliceReducer = teamSlice.reducer;
