/**
 * Avoid needing to manage the types and track the IDs returned from setInterval.
 */
export class BrowserIntervalCtrl {
  constructor(private runImmediately = false) {}
  private next: ReturnType<typeof setInterval> | null = null;

  set(...args: Parameters<typeof setInterval>): void {
    this.clear();
    if (this.runImmediately) {
      args[0]();
    }
    this.next = setInterval(...args);
  }

  clear(): void {
    if (this.next) clearInterval(this.next);
  }
}
