import 'firebase/auth';
import 'firebase/database';

import firebase from 'firebase/app';

import { withFnPrefix } from '@lp-lib/cloud-functions-prefix';
import { type FirebaseSafeRead } from '@lp-lib/firebase-typesafe';

import defaultConfig, { type FirebaseConfig } from '../../config';
import { apiService } from '../../services/api-service';
import { type NarrowedWebDatabaseReference } from './types';

export class FirebaseService {
  private app: firebase.app.App;
  private db?: firebase.database.Database;
  constructor(
    private readonly config: FirebaseConfig = defaultConfig.firebase
  ) {
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp({
        apiKey: config.apiKey,
        databaseUrl: config.databaseUrl,
        projectId: config.projectId,
      });
    } else {
      this.app = firebase.app();
    }
    this.config = config;
  }

  private auth(): firebase.auth.Auth {
    const auth = this.app.auth();
    if (this.config.useEmulator) {
      if (!this.config.authUrl) {
        throw new Error('authUrl is required');
      }
      // @ts-expect-error: types are not updated in the lib https://github.com/firebase/firebase-js-sdk/pull/4430
      auth.useEmulator(this.config.authUrl, { disableWarnings: true });
    }
    return auth;
  }

  async signIn(): Promise<firebase.User | null> {
    const auth = this.auth();
    if (auth.currentUser !== null) {
      return auth.currentUser;
    }
    const res = await apiService.auth.getFirebaseToken();
    const userCredential = await firebase
      .auth()
      .signInWithCustomToken(res.data.token);
    return userCredential.user;
  }

  async signOut(): Promise<void> {
    const auth = this.auth();
    return auth.signOut();
  }

  database(): firebase.database.Database {
    if (this.db) {
      return this.db;
    }
    firebase.database.enableLogging(this.config.databaseLoggingEnabled);
    this.db = this.app.database(this.config.databaseUrl);
    if (this.config.useEmulator) {
      const url = new URL(this.config.databaseUrl);
      this.db.useEmulator(url.hostname, Number(url.port));
    }
    return this.db;
  }

  /**
   * @deprecated Please use prefixedSafeRef instead.
   */
  prefixedRef<T>(path: string): NarrowedWebDatabaseReference<T> {
    const prefixed = withFnPrefix(path, this.config);
    return this.database().ref(prefixed) as NarrowedWebDatabaseReference<T>;
  }

  /**
   * Given a string path and a matching T structure, create a fully-typed
   * Firebase RTDB Reference. This is considered "safe" because it recursively
   * converts any null _types_ to a consistent type for Firebase: T | null -> T
   * | undefined. `null` is not generally allowed as a stored value in RTDB.
   */
  prefixedSafeRef<T>(
    path: string
  ): NarrowedWebDatabaseReference<FirebaseSafeRead<T>> {
    const prefixed = withFnPrefix(path, this.config);
    return this.database().ref(prefixed) as NarrowedWebDatabaseReference<
      FirebaseSafeRead<T>
    >;
  }

  /**
   * @deprecated Please use safeRef
   */
  ref<T>(path: string): NarrowedWebDatabaseReference<T> {
    return this.database().ref(path) as NarrowedWebDatabaseReference<T>;
  }

  safeRef<T>(path: string): NarrowedWebDatabaseReference<FirebaseSafeRead<T>> {
    return this.database().ref(path) as NarrowedWebDatabaseReference<
      FirebaseSafeRead<T>
    >;
  }

  prefixedPath(path: string): string {
    return withFnPrefix(path, this.config);
  }

  prefix(): string {
    return this.config.functionsPrefix;
  }

  createConnectionStatusHandle() {
    const ref = this.safeRef<Nullable<boolean>>('.info/connected');

    const handle = {
      connected: null as Nullable<boolean>,
      destroy: () => ref.off(),
    };

    // Also provides the initial value.
    ref.on('value', (snap) => {
      handle.connected = snap.val();
    });

    return handle;
  }

  connect(): void {
    this.database().goOnline();
  }

  disconnect(): void {
    this.database().goOffline();
  }
}

export const firebaseService = new FirebaseService();
