import { type DtoBrand } from '@lp-lib/api-service-client/public';

import {
  type Game,
  type GameActionType,
  type GamePack,
  type GamePackActionType,
} from './game';
import { type Organizer } from './organization';

export enum NotificationType {
  TeamInvite = 'TeamInvite',
  GameAction = 'GameAction',
  TeamRandomizer = 'TeamRandomizer',
  TeamCaptainScribe = 'TeamCaptainScribe',
  TeamSizeChanged = 'TeamSizeChanged',
  GamePackAction = 'GamePackAction',
  OrgAction = 'OrgAction',
  SkipTeamRandomizerIcebreaker = 'SkipTeamRandomizerIcebreaker',
  LiteModeSuggestion = 'LiteModeSuggestion',
  GameChangeSubmitter = 'GameChangeSubmitter',
  BrandAction = 'BrandAction',
  WaterCoolerAction = 'WaterCoolerAction',
  TownhallLargeGroup = 'TownhallLargeGroup',
  General = 'General',
  GuestCouldNotJoin = 'GuestCouldNotJoin',
  GamePackRequested = 'GamePackRequested',
}

type BaseNotification = {
  id: string;
  toUserClientId: string;
  type: NotificationType;
  createdAt: number;
  metadata?: unknown;
};

export type OrgActionNotification = BaseNotification & {
  type: NotificationType.OrgAction;
  metadata: {
    actionType: 'resend';
    organizer: Organizer;
  };
};

export type TeamInviteNotification = BaseNotification & {
  type: NotificationType.TeamInvite;
  metadata: {
    teamId: string;
    inviterClientId: string;
  };
};

export type GameActionNotification = BaseNotification & {
  type: NotificationType.GameAction;
  metadata: {
    actionType: GameActionType;
    game: Game;
    numOfBlocks?: number;
  };
};

export type TeamRandomizerNotification = BaseNotification & {
  type: NotificationType.TeamRandomizer;
  metadata: {
    taskId: string;
  };
};

export type TeamCaptainScribeNotification = BaseNotification & {
  type: NotificationType.TeamCaptainScribe;
};

export type TeamSizeChangedNotification = BaseNotification & {
  type: NotificationType.TeamSizeChanged;
  metadata: {
    maxMembers: number;
  };
};

export type GamePackActionNotification = BaseNotification & {
  type: NotificationType.GamePackAction;
  metadata: {
    actionType: GamePackActionType;
    gamePack: GamePack;
  };
};

export type SkipTeamRandomizerIcebreakerNotification = BaseNotification & {
  type: NotificationType.SkipTeamRandomizerIcebreaker;
};

export type LiteModeSuggestionNotification = BaseNotification & {
  type: NotificationType.LiteModeSuggestion;
};

export type GameChangeSubmitterNotification = BaseNotification & {
  type: NotificationType.GameChangeSubmitter;
  metadata: {
    droppedPlayerClientId: string;
    newPlayerClientId: string;
  };
};

export type BrandActionNotification = BaseNotification & {
  type: NotificationType.BrandAction;
  metadata: {
    brand: DtoBrand;
  };
};

export type WaterCoolerNotification = BaseNotification & {
  type: NotificationType.WaterCoolerAction;
  metadata: {
    content: string;
  };
};

export type TownhallLargeGroupNotification = BaseNotification & {
  type: NotificationType.TownhallLargeGroup;
};

export type GeneralNotification = BaseNotification & {
  type: NotificationType.General;
  metadata: {
    message: string;
  };
};

export type GuestCouldNotJoinNotification = BaseNotification & {
  type: NotificationType.GuestCouldNotJoin;
  metadata: {
    seatCap: number;
  };
};

export type GamePackRequestedNotification = BaseNotification & {
  type: NotificationType.GamePackRequested;
  metadata: {
    gamePackId: string;
    requestedByClientId: string;
  };
};

export type Notification =
  | TeamInviteNotification
  | GameActionNotification
  | TeamRandomizerNotification
  | TeamCaptainScribeNotification
  | TeamSizeChangedNotification
  | GamePackActionNotification
  | OrgActionNotification
  | SkipTeamRandomizerIcebreakerNotification
  | LiteModeSuggestionNotification
  | GameChangeSubmitterNotification
  | BrandActionNotification
  | WaterCoolerNotification
  | TownhallLargeGroupNotification
  | GeneralNotification
  | GuestCouldNotJoinNotification
  | GamePackRequestedNotification;
