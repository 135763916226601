// admin.database.ServerValue.TIMESTAMP == the following.
export const RTDBServerValueTIMESTAMP = { '.sv': 'timestamp' } as const;
export type RTDBServerValueTIMESTAMP = typeof RTDBServerValueTIMESTAMP;

export function isServerValue<T>(
  val: T | RTDBServerValueTIMESTAMP
): val is RTDBServerValueTIMESTAMP {
  if (val && typeof val === 'object' && '.sv' in val) return true;
  return false;
}
