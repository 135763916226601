import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit';
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit';

import { type AppDispatch, type RootState } from './types';

// https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

/**
 * @example
 *
 *```ts
 *const config: AppListenerOptions = { ... };
 *startAppListening(config);
 *```
 */
export type AppListenerOptions = Parameters<AppStartListening>[0];
