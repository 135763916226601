import firebase from 'firebase/app';

import { type NarrowedDatabaseReference } from '@lp-lib/firebase-typesafe';

import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { type FirebaseWebRewraper, type RecoveryConfig } from './types';

/**
 * This wrapper is used to avoid the type error since the
 * firebase.database.ServerValue.increment retuens an object.
 * That object is just a placeholder, and the code will be updated
 * on the remote Firebase Server. In the local, we should never get
 * that object.
 * @param delta
 * @returns
 */
export function increment(delta: number): never {
  return firebase.database.ServerValue.increment(delta) as never;
}

export class FirebaseUtils {
  static RecoveryConfig(
    intervalMs = getFeatureQueryParamNumber(
      'firebase-auto-recovery-interval-ms'
    ),
    maxAttempts = getFeatureQueryParamNumber(
      'firebase-auto-recovery-max-attempts'
    )
  ): RecoveryConfig {
    return {
      intervalMs: intervalMs,
      maxAttempts: maxAttempts,
      totalMs: intervalMs * maxAttempts,
    };
  }
  static Rewrap<T>(
    ref: NarrowedDatabaseReference<T, firebase.database.DataSnapshot>
  ) {
    return ref as FirebaseWebRewraper<typeof ref>;
  }

  static RewrapNullable<T>(
    ref: Nullable<NarrowedDatabaseReference<T, firebase.database.DataSnapshot>>
  ) {
    return ref as Nullable<FirebaseWebRewraper<typeof ref>>;
  }
}
