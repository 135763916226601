import {
  ConnectionStatus,
  type ConnectionStatusMixin,
} from '@lp-lib/shared-schema';

import { type FirebaseService } from '../components/Firebase';
import { FirebaseUtils } from '../components/Firebase/utils';
import config from '../config';

export function isTimeout(
  entity: ConnectionStatusMixin,
  timeout: number = config.app.userConnection.cleanupTimeout
): boolean {
  const timeElasped = Date.now() - (entity.disconnectedAt || 0);
  return (
    entity.status === ConnectionStatus.Disconnected && timeElasped > timeout
  );
}

export function isReconnecting(entity: ConnectionStatusMixin): boolean {
  return !isTimeout(entity, FirebaseUtils.RecoveryConfig().totalMs);
}

export class FBPathUtils {
  static ForParticipants(
    svc: FirebaseService,
    venueId: string,
    clientId?: string
  ): string {
    const base = `participants/${venueId}`;
    return svc.prefixedPath(clientId ? `${base}/${clientId}` : base);
  }
  static ForTeams(
    svc: FirebaseService,
    venueId: string,
    teamId?: string
  ): string {
    const base = `teams/${venueId}`;
    return svc.prefixedPath(teamId ? `${base}/${teamId}` : base);
  }
  static ForTeamMembers(
    svc: FirebaseService,
    venueId: string,
    member?: { teamId: string; memberId: string }
  ): string {
    const base = `team-members/${venueId}`;
    return svc.prefixedPath(
      member ? `${base}/${member.teamId}/${member.memberId}` : base
    );
  }
  static ForHeartbeat(svc: FirebaseService, venueId: string): string {
    return svc.prefixedPath(`heartbeat/${venueId}`);
  }
  static ForNetworkQualities(
    svc: FirebaseService,
    venueId: string,
    clientId?: string
  ): string {
    const base = `network-qualities/${venueId}`;
    return svc.prefixedPath(clientId ? `${base}/${clientId}` : base);
  }
  static ForAgoraNumericUidTracking(
    svc: FirebaseService,
    venueId: string,
    clientId?: string
  ): string {
    const base = `anu-tracking/${venueId}`;
    return svc.prefixedPath(clientId ? `${base}/${clientId}` : base);
  }
}
