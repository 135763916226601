import { asyncThunkCreator, buildCreateSlice } from '@reduxjs/toolkit';

/**
 * Use this to enable typed-ish async thunk creators within the call to
 * createSlice. See
 * https://redux-toolkit.js.org/api/createSlice#createasyncthunk
 */
export const createAppSlice = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});
