import { type TownhallMode } from './townhall';

export type TeamId = string;
export type MemberId = string;

export interface TeamMember {
  id: MemberId;
  joinedAt: number;
}

export interface Team {
  id: string;
  name: string;
  locked?: boolean;
  createdAt: number;
  captainScribe: string | null;
  isCohostTeam: boolean;
  color?: string;
  townhallMode?: TownhallMode;
}

export interface TeamV0 extends Team {
  readonly isFull: boolean;
  readonly membersCount: number;
  readonly isStaffTeam?: boolean; // undefined means unknown
}

export interface TeamInvitation {
  teamId: string;
  inviteeClientId: string;
  invitedAt: number;
}

export const TEAM_COLORS = [
  '#FBB707',
  '#15DF96',
  '#8C6FFF',
  '#E96B24',
  '#0029FF',
  '#8F00FF',
  '#CA2121',
  '#00D0C4',
  '#F0F33D',
  '#BF568F',
  '#79A669',
  '#706EBE',
  '#BE8260',
  '#537594',
  '#8D103D',
  '#0B2B99',
  '#978800',
  '#6D3C75',
  '#3988FF',
  '#FF3975',
];
