/* eslint-disable @lp-lib/eslint-rules/encapsulated-redux */

import { type PayloadAction } from '@reduxjs/toolkit';

import config from '../config';
import { NotificationType } from '../types/notification';
import { type TeamInvitation } from '../types/team';
import { uuidv4 } from '../utils/common';
import { type store } from './configureStore';
import { createAppSlice } from './createSlice';
// eslint-disable-next-line no-restricted-imports
import { sendNotification } from './notificationSlice';
import { type AppDispatch, type AppThunk, type RootState } from './types';

const cleanupInternal = 1000;
let cleanupTimerId: ReturnType<typeof setTimeout> | null;

interface TeamInviteState {
  venueId: string | null;
  invites: Record<string, TeamInvitation>;
}

const initialState: TeamInviteState = {
  venueId: null,
  invites: {},
};

const teamInviteSlice = createAppSlice({
  name: 'teamInvite',
  initialState,

  reducers: (create) => ({
    setVenueId: create.reducer((state, action: PayloadAction<string>) => {
      state.venueId = action.payload;
    }),

    addTeamInvitation: create.reducer(
      (state, action: PayloadAction<TeamInvitation>) => {
        state.invites[action.payload.inviteeClientId] = action.payload;
      }
    ),

    removeTeamInvitation: create.reducer(
      (state, action: PayloadAction<string>) => {
        delete state.invites[action.payload];
      }
    ),

    removeAll: create.reducer((state) => {
      state.invites = {};
    }),
  }),
});

export const {
  setVenueId,
  addTeamInvitation,
  removeTeamInvitation,
  removeAll,
} = teamInviteSlice.actions;

const startCleanupTimer = (
  dispatch: AppDispatch,
  getState: typeof store.getState
) => {
  const loop = async () => {
    const state = getState();
    for (const [clientId, invitation] of Object.entries(
      state.teamInvite.invites
    )) {
      if (Date.now() - invitation.invitedAt > config.team.inviteCooldown) {
        dispatch(removeTeamInvitation(clientId));
      }
    }
    if (Object.keys(state.teamInvite.invites).length > 0) {
      cleanupTimerId = setTimeout(loop, cleanupInternal);
    } else {
      cleanupTimerId = null;
    }
  };
  loop();
};

export const sendTeamInvite =
  (myClientId: Nullable<string>, invitation: TeamInvitation): AppThunk =>
  async (dispatch, getState) => {
    if (!myClientId) return;
    dispatch(addTeamInvitation(invitation));
    dispatch(
      sendNotification({
        id: uuidv4(),
        toUserClientId: invitation.inviteeClientId,
        type: NotificationType.TeamInvite,
        createdAt: invitation.invitedAt,
        metadata: {
          teamId: invitation.teamId,
          inviterClientId: myClientId,
        },
      })
    );
    if (!cleanupTimerId) {
      startCleanupTimer(dispatch as AppDispatch, getState);
    }
  };

export const resetTeamInvites = (): AppThunk => async (dispatch) => {
  dispatch(removeAll());
};

export const selectTeamInvitationByClientId = (
  state: RootState,
  clientId: string | null | undefined
): TeamInvitation | undefined => {
  if (!clientId) return;
  return state.teamInvite.invites[clientId];
};

export const teamInviteSliceReducer = teamInviteSlice.reducer;
