import { Link } from '@remix-run/react';
import React from 'react';

import { PortalLink } from '../PortalContext';
import { AdminLogoIcon, DefaultLogoIcon, HostLogoIcon } from './LogoIcon';

const LogoLink = (props: {
  path: string;
  children: React.ReactNode;
  className: string;
}): JSX.Element => {
  return (
    <Link className={`${props.className}`} to={props.path} reloadDocument>
      {props.children}
    </Link>
  );
};

type LPLogoType = 'admin' | 'host' | 'default';

interface LPLogoProps {
  type?: LPLogoType;
  className?: string;
}

export const LPLogo = (props: LPLogoProps): JSX.Element => {
  let icon = <DefaultLogoIcon />;
  let path = '/home';

  switch (props.type) {
    case 'admin':
      icon = <AdminLogoIcon />;
      path = PortalLink.AdminHome;
      break;
    case 'host':
      icon = <HostLogoIcon />;
      path = PortalLink.HostHome;
      break;
    case 'default':
    default:
      break;
  }

  return (
    <LogoLink
      className={props.className ?? 'w-12.5 h-12.5 inline-block'}
      path={path}
    >
      {icon}
    </LogoLink>
  );
};

/**
 * Small wrapper of LPLogo used in the public navigation header.
 */
export function LPNavLogo(): JSX.Element {
  return (
    <div className='w-20 h-20 flex items-center justify-center bg-layer-002'>
      <LPLogo className='flex-none w-12.5 h-12.5 inline-block' />
    </div>
  );
}
