export enum ConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export interface ConnectionStatusMixin {
  status?: ConnectionStatus;
  disconnectedAt?: number;
  disconnectedReason?: string;
}
