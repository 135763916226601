export type FunctionsPrefix =
  | 'prod'
  | 'dev'
  | 'localdev'
  | 'test'
  | `pr${number}`;

export function withFnPrefix(
  path: string,
  env: { functionsPrefix: FunctionsPrefix }
): string {
  const prefix = env.functionsPrefix;
  if (!prefix) throw new Error(`EmptyPrefixError`);
  return `/${prefix}/${path}`.replace(/\/+/g, '/');
}
