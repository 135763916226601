/* eslint-disable @lp-lib/eslint-rules/encapsulated-redux */
import 'firebase/database';

import { type PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { firebaseService } from '../components/Firebase';
import { type NarrowedWebDatabaseReference } from '../components/Firebase/types';
import { useLiveCallback } from '../hooks/useLiveCallback';
import logger from '../logger/logger';
import {
  type Notification,
  type NotificationType,
} from '../types/notification';
import { createAppSlice } from './createSlice';
import { FirebaseRefNotInitializedError } from './exception';
import { useAppDispatch } from './hooks';
import { type AppThunk, type RootState } from './types';
import { uncheckedIndexAccess_UNSAFE } from '../utils/uncheckedIndexAccess_UNSAFE';

const log = logger.scoped('notificationSlice');

type NotificationMap = Record<string, Notification>;

interface NotificationState {
  venueId: string | null;
  myClientId: string | null;
  notifications: NotificationMap;
}

let notificationRef: NarrowedWebDatabaseReference<NotificationMap> | null =
  null;

class NotificationRefNotInitializedError extends FirebaseRefNotInitializedError {
  constructor(message = 'notifications/<venueId>/<clientId>') {
    super(message);
  }
}

const initialState: NotificationState = {
  venueId: null,
  myClientId: null,
  notifications: {},
};

const notificationSlice = createAppSlice({
  name: 'notification',
  initialState,
  reducers: {
    setVenueId: (state, action: PayloadAction<string>) => {
      state.venueId = action.payload;
    },
    setMyClientId: (state, action: PayloadAction<string>) => {
      state.myClientId = action.payload;
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications[action.payload.id] = action.payload;
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      delete state.notifications[action.payload];
    },
    reset: (state) => {
      Object.keys(initialState).forEach((key) => {
        uncheckedIndexAccess_UNSAFE(state)[key] =
          uncheckedIndexAccess_UNSAFE(initialState)[key];
      });
    },
  },
});

export const {
  setVenueId,
  setMyClientId,
  addNotification,
  removeNotification,
  reset,
} = notificationSlice.actions;

const init =
  (venueId: string, myClientId: string): AppThunk<Promise<() => void>> =>
  async (dispatch) => {
    if (!notificationRef) {
      notificationRef = firebaseService.prefixedRef<NotificationMap>(
        `notifications/${venueId}/${myClientId}`
      );
    }
    notificationRef.on('child_added', (snapshot) => {
      const data = snapshot.val();
      log.debug('notifications child_added', { notification: data });
      if (!data) return;
      dispatch(addNotification(data));
    });
    notificationRef.on('child_changed', (snapshot) => {
      const data = snapshot.val();
      log.debug('notifications child_changed', { notification: data });
      if (!data) return;
      dispatch(addNotification(data));
    });
    notificationRef.on('child_removed', (snapshot) => {
      const data = snapshot.val();
      log.debug('notifications child_removed', { notification: data });
      if (!data) return;
      dispatch(removeNotification(data.id));
    });
    dispatch(setVenueId(venueId));
    dispatch(setMyClientId(myClientId));
    await notificationRef.onDisconnect().remove((err) => {
      if (err) {
        log.error('notifications onDisconnect.remove failed', err);
      }
    });
    return () => {
      notificationRef?.off();
      dispatch(reset());
    };
  };

export const sendNotification =
  (notification: Notification): AppThunk =>
  async () => {
    if (!notificationRef) return;
    const ref = notificationRef.parent
      ?.child(notification.toUserClientId)
      .child(notification.id);
    await ref?.set(notification);
  };

export const dismissNotification =
  (id: string): AppThunk =>
  async (dispatch) => {
    if (!notificationRef) {
      throw new NotificationRefNotInitializedError();
    }
    await notificationRef.child(id).remove();
    dispatch(removeNotification(id));
    // Ideally, we should not dismiss the toast in the Redux.
    // But it's convenient to add it here to make the caller easier.
    toast.dismiss(id);
  };

export const dismissNotificationByType =
  (type: NotificationType, excludedNotificationId?: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (!notificationRef) {
      throw new NotificationRefNotInitializedError();
    }
    const toBeDismissed: string[] = [];
    for (const [id, notification] of Object.entries(
      state.notification.notifications
    )) {
      if (
        notification.type === type &&
        notification.id !== excludedNotificationId
      ) {
        toBeDismissed.push(id);
      }
    }
    for (const id of toBeDismissed) {
      dispatch(dismissNotification(id));
    }
  };

export const selectNotifications = (
  state: RootState,
  sort = true
): Notification[] => {
  let notifications = Object.values(state.notification.notifications);

  if (sort)
    notifications = notifications.sort((a, b) => a.createdAt - b.createdAt);
  return notifications;
};

export const notificationSliceReducer = notificationSlice.reducer;

export function useInitNotificationStore() {
  const dispatch = useAppDispatch();
  return useLiveCallback((...args: Parameters<typeof init>) =>
    dispatch(init(...args))
  );
}
