import { combineReducers } from '@reduxjs/toolkit';

// eslint-disable-next-line no-restricted-imports
import { participantSliceReducer } from '../components/Player/participantSlice';
// eslint-disable-next-line no-restricted-imports
import { teamSliceReducer } from '../components/TeamAPI/teamSlice';
// eslint-disable-next-line no-restricted-imports
import { notificationSliceReducer } from './notificationSlice';
// eslint-disable-next-line no-restricted-imports
import { teamInviteSliceReducer } from './teamInviteSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createRootReducer = () =>
  combineReducers({
    participant: participantSliceReducer,
    team: teamSliceReducer,
    notification: notificationSliceReducer,
    teamInvite: teamInviteSliceReducer,
  });

export { createRootReducer };
