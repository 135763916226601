import '../vendor/rstats/rstats.css';

import RStats from '../vendor/rstats/rstats.js';
import { BrowserTimeoutCtrl } from './BrowserTimeoutCtrl';
import {
  makeRStatsCounterConfig,
  RStatsCounterNames,
  type RStatsCounterNamesT,
} from './rstats-config';

type RStatsCtrlOptions = {
  isHostClient: boolean;
  userTimingEnabled: boolean;
};

class RStatsCtrl {
  private config: RStats.RStatsSettings<RStatsCounterNamesT>;
  private rs;

  instance = () => this.rs();
  counter = (name: RStatsCounterNamesT) => this.rs<RStatsCounterNamesT>(name);

  private rafHandle: number | null = null;

  private previousPruneAt = performance.now();
  // How long to wait before pruning performance marks and measures. Firefox
  // performance especially begins to decay after a couple of thousand.
  private userTimingTTLMs = 5 * 60 * 1000;

  private aborter = new AbortController();
  private observer: PerformanceObserver | null = null;

  infrequents = new InfrequentDecayCounters();

  constructor(options: RStatsCtrlOptions) {
    this.config = makeRStatsCounterConfig(
      options.isHostClient,
      options.userTimingEnabled
    );
    this.rs = new RStats<RStatsCounterNamesT>(this.config);
    const el = this.instance().element;
    const style = el.style;
    style.setProperty('--rs-bottom', '0.25rem');
    style.setProperty('--rs-top', 'auto');

    style.setProperty('--rs-bg', 'rgba(0, 0, 0, 0.8)');
    style.setProperty('--rs-opacity', '0.5');
    style.setProperty('--rs-width', '800px');
    style.setProperty('--rs-counter-value-left', '400px');
    style.setProperty('--rs-counter-value-text-align', 'left');
    style.setProperty('--rs-alarm-text-shadow', '#000');
    style.borderRadius = '0.75rem';

    this.start();
  }

  attach(destination: HTMLElement) {
    const el = this.instance().element;
    destination.appendChild(el);
  }

  destroy() {
    this.aborter.abort();
    this.observer?.disconnect();
    this.observer = null;

    this.instance().element.remove();
    this.stop();
  }

  start() {
    this.stop();

    this.observer = new PerformanceObserver((list) => {
      let totalDelayMs = 0;
      let totalProcessingTimeMs = 0;
      let totalDurationMs = 0;
      const targets = new Set();
      list.getEntries().forEach((entry) => {
        if (entry.entryType !== 'event') return;
        const e = entry as PerformanceEventTiming;
        // Do some simple deduping, since a "click", for example, will usually
        // result in the mousedown, mouseup, click, etc.
        if (targets.has(e.target)) return;
        // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceEventTiming/processingStart#using_the_processingstart_property
        const inputDelayMs = e.processingStart - e.startTime;
        const processingTimeMs = e.processingStart - e.processingEnd;
        targets.add(e.target);
        totalDelayMs += inputDelayMs;
        totalProcessingTimeMs += processingTimeMs;
        totalDurationMs += e.duration;
      });
      targets.clear();
      rsInfrequentSet('slow-events-delay-ms', totalDelayMs, undefined, this);
      rsInfrequentSet(
        'slow-events-processing-ms',
        totalProcessingTimeMs,
        undefined,
        this
      );
      rsInfrequentSet(
        'slow-events-duration-ms',
        totalDurationMs,
        undefined,
        this
      );
    });

    this.observer.observe({ type: 'event', buffered: true });

    const tick = () => {
      // Prune known performance marks and measures. Neither chrome nor firefox
      // manage this automatically, unless the page is unloaded. Firefox
      // performance degrades significantly (e.g. starts taking up 50% of CPU
      // time!) once the number of marks increases beyond a few hundred (my
      // guess is something to do with how `performance.measure()` does its
      // lookups is not optimized at all?).
      const now = performance.now();

      if (
        this.config.userTimingAPI &&
        now - this.previousPruneAt >= this.userTimingTTLMs
      ) {
        rsInfrequentSet(
          'rstats-perf-entries-c',
          performance.getEntries().length,
          500,
          this
        );
        this.previousPruneAt = now;
        rsCounter('rstats-marks-measures-prune-ms', this)?.start();
        for (let i = 0; i < RStatsCounterNames.length; i++) {
          // rstats uses these conventions
          const prefix = RStatsCounterNames[i];
          const start = `${prefix}-start`;
          const end = `${prefix}-end`;
          performance.clearMarks(start);
          performance.clearMarks(end);
          performance.clearMeasures(prefix);
        }
        rsCounter('rstats-marks-measures-prune-ms', this)?.end();
      }

      // mark fps
      rsCounter('frame', this)?.frame();
      // flush updates to dom/graphs
      rsCounter('rstats-render-ms', this)?.start();
      this.rs().update();
      rsCounter('rstats-render-ms', this)?.end();
      // schedule next frame
      this.rafHandle = requestAnimationFrame(tick);
    };

    tick();
  }

  stop() {
    if (this.rafHandle !== null) cancelAnimationFrame(this.rafHandle);
  }
}

class InfrequentDecayCounters {
  m = new Map<RStatsCounterNamesT, BrowserTimeoutCtrl>();
  // It's not as accurate to wait beyond 1 frame (16ms), but it helps the
  // developer see the value briefly before it is reset to 0.
  waitMs = 500;

  resetFor(name: RStatsCounterNamesT) {
    const infrequent = this.m.get(name);
    if (infrequent) {
      infrequent.clear();
      return infrequent;
    } else {
      const t = new BrowserTimeoutCtrl();
      this.m.set(name, t);
      return t;
    }
  }

  mark(
    name: RStatsCounterNamesT,
    callback: () => void,
    decayAfterMs = this.waitMs
  ) {
    const ctrl = this.resetFor(name);
    ctrl.set(callback, decayAfterMs);
  }
}

let ctrl: null | RStatsCtrl = null;

export function rsInit(
  options: RStatsCtrlOptions,
  destination: Nullable<HTMLElement>
) {
  rsDestroy();
  ctrl = new RStatsCtrl(options);
  if (destination) ctrl.attach(destination);
}

export function rsDestroy() {
  if (!ctrl) return;
  ctrl.destroy();
  ctrl = null;
}

/**
 * Get a reference to an rstats counter object, and use its full api as you
 * wish. See http://spite.github.io/rstats/ for usage examples.
 *
 * NOTE: RStats will also mark all counters to the Browser Performance Timeline!
 * So you can view all of these operations when taking a profile, after the
 * fact!
 */
export function rsCounter(name: RStatsCounterNamesT, c = ctrl) {
  c?.infrequents.resetFor(name);
  return c?.counter(name);
}

/**
 * RStats doesn't update or decay any counters unless a new value is written.
 * Some counters are only written to sporadically, such as tracking a specific
 * request duration. You don't want that counter's graph to be pinned to the
 * duration constantly! This utility will automatically set the value to 0 after
 * a short delay so the graph eventually settles at zero and shows spikes rather
 * than constants.
 */
export function rsInfrequent(
  name: RStatsCounterNamesT,
  decayAfterMs?: number,
  c = ctrl
) {
  c?.infrequents.mark(name, () => c?.counter(name).set(0), decayAfterMs);
}

/**
 * Helper that combines the operation of setting a counter, then marking it as
 * an infrequent counter since this is a common operation.
 */
export function rsInfrequentSet(
  name: RStatsCounterNamesT,
  value: number,
  decayAfterMs?: number,
  c = ctrl
) {
  if (!c) return;
  c.counter(name).set(value);
  rsInfrequent(name, decayAfterMs, c);
}

/**
 * Helper to allow incrementing a counter without having to read the current
 * value. `rsInfrequent()` will _only_ be triggered if `decayAfterMs` is defined.
 */
export function rsIncrement(
  name: RStatsCounterNamesT,
  decayAfterMs?: number,
  c = ctrl
) {
  c?.infrequents.resetFor(name);
  const counter = c?.counter(name);
  const val = counter?.value() ?? 0;
  counter?.set(val + 1);
  if (decayAfterMs !== undefined) rsInfrequent(name, decayAfterMs, c);
}
