import 'firebase/database';

import { type PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import firebase from 'firebase/app';

import { RTDBServerValueTIMESTAMP } from '@lp-lib/firebase-typesafe';
import {
  ConnectionStatus,
  type ConnectionStatusMixin,
} from '@lp-lib/shared-schema';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
} from '../../hooks/useFeatureQueryParam';
import logger from '../../logger/logger';
import { type NetworkQualityState } from '../../services/webrtc';
import { createAppSlice } from '../../store/createSlice';
import { SliceNotInitializedError } from '../../store/exception';
import { type AppDispatch, type GetAppState } from '../../store/types';
import { FBPathUtils } from '../../store/utils';
import {
  intoParticipantFlags,
  intoParticipantSkim,
  type Participant,
  type ParticipantFlagMap,
  type ParticipantFull,
  type ParticipantFullMap,
  type ParticipantMap,
} from '../../types/user';
import { assertDefinedFatal } from '../../utils/common';
import { type EmitterListener } from '../../utils/emitter';
import { safeAssign } from '../../utils/object';
import { rsIncrement } from '../../utils/rstats.client';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';
import { Clock } from '../Clock';
import {
  type FirebaseEvents,
  type FirebaseService,
  FirebaseValueHandle,
} from '../Firebase';
import { type NarrowedWebDatabaseReference } from '../Firebase/types';
import { FirebaseUtils } from '../Firebase/utils';

const log = logger.scoped('participantSlice');

const flushStyle = getFeatureQueryParamArray('participant-flush');

const CONFIG = {
  heartbeatInternal: 30 * 1000,
  addRemoveFlushInterval: 200,
  recoveryTimeoutMs: FirebaseUtils.RecoveryConfig().totalMs,
};

// Caution, this firebase value is cleaned up by the backend.
type HeartbeatMap = {
  [clientId: string]: {
    lastHeartbeatAt: RTDBServerValueTIMESTAMP;
  };
};

type ChannelUidHistoryMap = {
  team: { [uid: string]: number };
};

// Caution, this firebase value is cleaned up by the backend.
type AgoraNumericUidTracking = {
  [clientId: string]: ChannelUidHistoryMap;
};

interface ParticipantNetworkQuality extends NetworkQualityState {
  clientId: string;
}

// Caution, this firebase value is cleaned up by the backend.
type NetworkQualitiesMap = {
  [clientId: string]: ParticipantNetworkQuality | undefined;
};

interface ParticipantState {
  venueId: string | null;
  myClientId: string | null;
  skims: ParticipantMap;
  fulls: ParticipantFullMap;
  flags: ParticipantFlagMap;
  networkQualities: NetworkQualitiesMap;
}

class ParticipantSliceNotInitializedError extends SliceNotInitializedError {
  constructor() {
    super('participant');
  }
}

function makeParticipantHandle(
  svc: FirebaseService,
  venueId: string
): FirebaseValueHandle<ParticipantFullMap> {
  return new FirebaseValueHandle(
    svc.safeRef(FBPathUtils.ForParticipants(svc, venueId))
  );
}

export async function writeParticipantConnected(
  svc: FirebaseService,
  venueId: string,
  clientId: Participant['clientId']
) {
  const ref = FirebaseUtils.Rewrap(
    makeParticipantHandle(svc, venueId).ref.child(clientId)
  );
  await ref.update({
    status: ConnectionStatus.Connected,
    disconnectedAt: null as never,
    disconnectedReason: null as never,
  });
}

export async function writeParticipantDisconnected(
  svc: FirebaseService,
  venueId: string,
  clientId: Participant['clientId']
) {
  const ref = FirebaseUtils.Rewrap(
    makeParticipantHandle(svc, venueId).ref.child(clientId)
  );
  await ref.update({
    status: ConnectionStatus.Disconnected,
    disconnectedAt: RTDBServerValueTIMESTAMP as never,
    disconnectedReason: 'writeParticipantDisconnected',
  });
}

export class ParticipantStoreV1 {
  private participantListRef: NarrowedWebDatabaseReference<ParticipantFullMap> | null =
    null;
  private heartbeatRef: NarrowedWebDatabaseReference<HeartbeatMap> | null =
    null;
  private heartbeatTimerId?: ReturnType<typeof setInterval>;
  private addRemoveFlushTimerId?: ReturnType<typeof setInterval> | null;

  private qualitiesRef: NarrowedWebDatabaseReference<NetworkQualitiesMap> | null =
    null;

  private anuTrackingRef: NarrowedWebDatabaseReference<AgoraNumericUidTracking> | null =
    null;

  private connectionAborter = new AbortController();
  private initialized = false;

  constructor(
    private venueId: string,
    private svc: FirebaseService,
    private emitter: EmitterListener<FirebaseEvents>,
    private dispatch: AppDispatch,
    private getState: GetAppState
  ) {}

  private assertInitialized() {
    if (!this.initialized) throw new ParticipantSliceNotInitializedError();
  }

  private abortAndResetConnectionAborter() {
    this.connectionAborter.abort();
    this.connectionAborter = new AbortController();
  }

  async init() {
    const participantListRef = this.svc.ref<ParticipantFullMap>(
      FBPathUtils.ForParticipants(this.svc, this.venueId)
    );
    const snapshot = await participantListRef.get();
    const data = snapshot.val();
    if (data) {
      this.dispatch(participantSlice.actions.setParticipants(data));
    }

    const heartbeatRef = this.svc.ref<HeartbeatMap>(
      FBPathUtils.ForHeartbeat(this.svc, this.venueId)
    );

    const qualitiesRef = this.svc.ref<NetworkQualitiesMap>(
      FBPathUtils.ForNetworkQualities(this.svc, this.venueId)
    );

    const anuTrackingRef = this.svc.ref<AgoraNumericUidTracking>(
      FBPathUtils.ForAgoraNumericUidTracking(this.svc, this.venueId)
    );

    // Hack: enqueue new/edited participants, and participants to remove, in
    // order to prevent excessive redux thrashing when joining or when lots of
    // users are joining the venue sequentially.

    const addedOrChanged: ParticipantFull[] = [];
    const removed: string[] = []; // clientIds
    const qualitiesAddedOrChanged: ParticipantNetworkQuality[] = [];

    const flush = () => {
      if (addedOrChanged.length > 0) {
        this.dispatch(participantSlice.actions.addParticipants(addedOrChanged));
        addedOrChanged.length = 0;
      }

      if (removed.length > 0) {
        this.dispatch(participantSlice.actions.removeParticipants(removed));
        removed.length = 0;
      }

      if (qualitiesAddedOrChanged.length > 0) {
        this.dispatch(
          participantSlice.actions.setNetworkQualities(qualitiesAddedOrChanged)
        );
        qualitiesAddedOrChanged.length = 0;
      }
    };

    const addRemoveFlushTimerId =
      flushStyle === 'immediate'
        ? null
        : setInterval(() => flush(), CONFIG.addRemoveFlushInterval);

    participantListRef.on('child_added', (snapshot) => {
      const data = snapshot.val();
      log.debug('participants child_added', { participant: data });
      if (!data) return;
      addedOrChanged.push(data);
      if (flushStyle === 'immediate') flush();
    });
    participantListRef.on('child_changed', (snapshot) => {
      const data = snapshot.val();
      log.debug('participants child_changed', { participant: data });
      if (!data) return;
      addedOrChanged.push(data);
      if (flushStyle === 'immediate') flush();
    });
    participantListRef.on('child_removed', (snapshot) => {
      const data = snapshot.val();
      log.debug('participants child_removed', { participant: data });
      if (!data) return;
      removed.push(data.clientId);
      if (flushStyle === 'immediate') flush();
    });

    qualitiesRef.on('child_added', (snapshot) => {
      const data = snapshot.val();
      if (!data) return;
      qualitiesAddedOrChanged.push(data);
      if (flushStyle === 'immediate') flush();
    });
    qualitiesRef.on('child_changed', (snapshot) => {
      const data = snapshot.val();
      if (!data) return;
      qualitiesAddedOrChanged.push(data);
      if (flushStyle === 'immediate') flush();
    });
    // Qualities are cleaned up by backend, we never remove because if the
    // participant is disconnected, perhaps we want to see their bad state. Or,
    // there is nothing looking at the data, no need to cause another update.

    this.participantListRef = participantListRef;
    this.heartbeatRef = heartbeatRef;
    this.qualitiesRef = qualitiesRef;
    this.anuTrackingRef = anuTrackingRef;
    this.addRemoveFlushTimerId = addRemoveFlushTimerId;
    this.initialized = true;

    // TODO(drew): is this necessary?
    this.dispatch(participantSlice.actions.setVenueId(this.venueId));
    log.info('inited');

    return () => this.destroy();
  }

  async join(data: Omit<Participant, 'joinedAt'>) {
    this.assertInitialized();
    const participant: ParticipantFull = {
      id: data.id,
      clientId: data.clientId,
      clientType: data.clientType,
      cohost: data.cohost,
      username: data.username,
      joinedAt: Date.now(),
      status: ConnectionStatus.Connected,
      // set default value to true
      hasCamera: true,
      hasMicrophone: true,
    };

    if (data.firstName && data.lastName) {
      participant.firstName = data.firstName;
      participant.lastName = data.lastName;
    }
    if (data.orgId) {
      participant.orgId = data.orgId;
    }

    // Note: we need to add the participant synchronously because other parts of
    // the code want to update the participant state. see useUserWatcher.
    this.dispatch(participantSlice.actions.addParticipant(participant));
    this.dispatch(participantSlice.actions.setMyClientId(data.clientId));
    const ref = FirebaseUtils.RewrapNullable(
      this.participantListRef?.child(participant.clientId)
    );
    assertDefinedFatal(ref, 'participantListRef');
    await ref.set(participant);
    await ref.onDisconnect().update(
      {
        status: ConnectionStatus.Disconnected,
        disconnectedAt: firebase.database.ServerValue.TIMESTAMP,
        disconnectedReason: 'ParticipantStoreV1#join',
      },
      (err) => {
        if (err) {
          log.error('participants onDisconnect.update failed', err);
        } else {
          log.info(`registered onDisconnect.update`, {
            disconnectedReason: 'ParticipantStoreV1#join',
          });
        }
      }
    );

    // vacuum up ghost participants related to this participant
    await this.cleanUpGhostParticipants(data.id, data.clientId);

    this.abortAndResetConnectionAborter();
    this.emitter.on(
      'connection-state-changed',
      (connected) => this.onConnectionStateChanged(data.clientId, connected),
      { signal: this.connectionAborter.signal }
    );
    this.startHeartbeat(participant.clientId);
  }

  // it's possible for some users to experience "ghost" users, where old versions of themselves, with different
  // clientIds, are still in the participant list, and still marked as connected. this might occur if some
  // onDisconnect hooks are not fired and the old client is not marked as disconnected.
  //
  // the purpose of this function is to clean out those "ghosts" when the client reconnects.
  //
  // this is the code equivalent of the Poltergust 3000 :)
  private async cleanUpGhostParticipants(
    participantUid: Participant['id'],
    participantClientId: Participant['clientId']
  ) {
    if (!this.participantListRef) return;

    const state = this.getState();
    const clientIdsCleaned = [];
    const updates: {
      [K in keyof ConnectionStatusMixin as `${string}/${K}`]: ConnectionStatusMixin[K];
    } = {};

    for (const participant of Object.values(state.participant.skims)) {
      if (
        participant &&
        participant.id === participantUid &&
        participant.clientId !== participantClientId &&
        participant.status === ConnectionStatus.Connected
      ) {
        clientIdsCleaned.push(participant.clientId);
        const ref = FBPathUtils.ForParticipants(
          this.svc,
          this.venueId,
          participant.clientId
        );
        updates[`${ref}/status`] = ConnectionStatus.Disconnected;
        // type wants a number, but let's use the server value.
        updates[`${ref}/disconnectedAt`] = RTDBServerValueTIMESTAMP as never;
        updates[`${ref}/disconnectedReason`] =
          'ParticipantStoreV1#cleanUpGhostParticipants';
      }
    }

    if (clientIdsCleaned.length > 0) {
      await this.svc.database().ref().update(updates);
      log.info('cleaned up ghost participants', {
        participantUid,
        participantClientId,
        clientIdsCleaned,
      });
    }
  }

  async leave(clientId: string, async?: boolean) {
    // this might be triggered from unload while the slice is not inited
    if (!this.initialized) return;

    const state = this.getState();
    const participant = state.participant.skims[clientId];
    if (!participant) return;
    const promises = [];
    const ref = FirebaseUtils.RewrapNullable(
      this.participantListRef?.child(clientId)
    );
    // TODO(drew): replace with Clock
    const now = Date.now();
    this.dispatch(
      participantSlice.actions.updateParticipant({
        clientId,
        status: ConnectionStatus.Disconnected,
        disconnectedAt: now,
      })
    );

    // NOTE(drew): when leaving the venue due to navigation, tab close, or other
    // abortive behavior, it's highly unlikley this .update() will successfully
    // complete. Therefore, it's more likely you'll see in the firebase
    // participant ref that the `disconnectedReason` is from the "join"
    // onDisconnect handler, rather than this write. I am leaving it here
    // because it cannot hurt, and is more informative if it does succeed.

    promises.push(
      ref?.update({
        status: ConnectionStatus.Disconnected,
        disconnectedAt: now,
        disconnectedReason: 'ParticipantStoreV1#leave',
      })
    );

    // NOTE(drew): Oddly, this onDisconnect.cancel() DOES often execute when
    // leaving the venue via tab close or navigate. This results in the hook
    // being canceled (it is initially registered during the `join` operation),
    // and the participant is never marked as disconnected! I am leaving it here
    // as a comment to inform us in the future.

    // promises.push(
    //   ref?.onDisconnect().cancel((err) => {
    //     if (err) {
    //       log.error('participants onDisconnect.cancel failed', err);
    //     } else {
    //       log.info(`canceled onDisconnect.update`, {
    //         disconnectedReason: 'ParticipantStoreV1#leave',
    //       });
    //     }
    //   })
    // );
    this.abortAndResetConnectionAborter();
    this.stopHeartbeat();
    this.stopAddRemoveFlush();
    if (!async) Promise.all(promises);
  }

  async sync() {
    this.assertInitialized();
    const snap = await this.participantListRef?.get();
    const data = snap?.val();
    if (data) this.dispatch(participantSlice.actions.setParticipants(data));
  }

  async update(clientId: string, data: Partial<ParticipantFull>) {
    this.assertInitialized();

    this.dispatch(
      participantSlice.actions.updateParticipant({ clientId, ...data })
    );
    const ref = this.participantListRef?.child(clientId);
    await ref?.update({ clientId, ...data });
  }

  private async onConnectionStateChanged(
    clientId: string,
    connected: boolean
  ): Promise<void> {
    this.assertInitialized();

    if (connected) {
      log.info('firebase connected, recover state.');
      const ref = FirebaseUtils.RewrapNullable(
        this.participantListRef?.child(clientId)
      );
      const snapshot = await ref?.get();
      if (!snapshot?.exists()) {
        log.warn('snapshot does not exist, skip recover.', {
          clientId,
        });
        return;
      }
      await writeParticipantConnected(this.svc, this.venueId, clientId);
      this.dispatch(
        participantSlice.actions.updateParticipant({
          clientId,
          status: ConnectionStatus.Connected,
          disconnectedAt: undefined,
        })
      );
      await ref?.onDisconnect().update(
        {
          status: ConnectionStatus.Disconnected,
          disconnectedAt: firebase.database.ServerValue.TIMESTAMP,
          disconnectedReason: 'ParticipantStoreV1#onConnectionStateChanged',
        },
        (err) => {
          if (err) {
            log.error('participants onDisconnect.update failed', err);
          } else {
            log.info(`registered onDisconnect.update`, {
              disconnectedReason: 'ParticipantStoreV1#onConnectionStateChanged',
            });
          }
        }
      );
    } else {
      log.info('firebase disconnected, mark self as disconnected');
      this.dispatch(
        participantSlice.actions.updateParticipant({
          clientId,
          status: ConnectionStatus.Disconnected,
          disconnectedAt: Date.now(),
        })
      );
    }
  }

  updateNetworkQuality(
    clientId: Participant['clientId'],
    uplinkNetworkQuality: number,
    downlinkNetworkQuality: number
  ) {
    this.dispatch(
      participantSlice.actions.setNetworkQuality({
        clientId,
        uplinkNetworkQuality,
        downlinkNetworkQuality,
      })
    );
  }

  private startHeartbeat(
    clientId: string,
    interval = CONFIG.heartbeatInternal
  ) {
    this.assertInitialized();
    this.stopHeartbeat();
    const ref = this.heartbeatRef?.child(clientId);
    this.heartbeatTimerId = setInterval(async () => {
      await ref?.update({
        lastHeartbeatAt: RTDBServerValueTIMESTAMP,
      });
    }, interval);
  }

  private stopHeartbeat() {
    if (this.heartbeatTimerId) {
      clearTimeout(this.heartbeatTimerId);
    }
  }

  private stopAddRemoveFlush() {
    if (this.addRemoveFlushTimerId) {
      clearInterval(this.addRemoveFlushTimerId);
    }
  }

  async trackAgoraNumericUid(
    clientId: Participant['clientId'],
    key: keyof ChannelUidHistoryMap,
    uid: number,
    ts: number
  ) {
    if (!this.anuTrackingRef) return;
    await this.anuTrackingRef
      .child(clientId)
      .child(key)
      .child(uid.toString())
      .set(ts);
  }

  destroy() {
    this.abortAndResetConnectionAborter();
    this.participantListRef?.off();
    this.qualitiesRef?.off();
    this.stopHeartbeat();
    this.stopAddRemoveFlush();
    this.dispatch(participantSlice.actions.reset());
    this.initialized = false;
    log.info('disposed');
  }
}

function safelyAssignParticipant(state: ParticipantState, p: ParticipantFull) {
  // Taking care to assign the existing object if it exists, and only
  // touching specific properties.

  state.fulls[p.clientId] = safeAssign(
    state.fulls[p.clientId],
    {
      teamId: undefined,
      orgId: undefined,
      userAgent: undefined,
      firstName: undefined,
      lastName: undefined,
      disconnectedAt: undefined,
      disconnectedReason: undefined,
      status: undefined,
      away: undefined,
      cohost: undefined,
      icon: undefined,

      audio: undefined,
      video: undefined,
      lite: undefined,
      hasMicrophone: undefined,
      hasCamera: undefined,
      onStage: undefined,
      onStageMuted: undefined,
      spectator: undefined,
      voiceOverLocale: undefined,
    },
    p
  );

  state.flags[p.clientId] = safeAssign(
    state.flags[p.clientId],
    {
      audio: undefined,
      video: undefined,
      lite: undefined,
      hasMicrophone: undefined,
      hasCamera: undefined,
      onStage: undefined,
      onStageMuted: undefined,
      spectator: undefined,
      voiceOverLocale: undefined,
    },
    intoParticipantFlags(p)
  );

  state.skims[p.clientId] = safeAssign(
    state.skims[p.clientId],
    {
      teamId: undefined,
      orgId: undefined,
      userAgent: undefined,
      firstName: undefined,
      lastName: undefined,
      disconnectedAt: undefined,
      disconnectedReason: undefined,
      status: undefined,
      away: undefined,
      cohost: undefined,
      icon: undefined,
    },
    intoParticipantSkim(p)
  );

  return state;
}

const initialState: ParticipantState = {
  venueId: null,
  myClientId: null,
  skims: {},
  fulls: {},
  flags: {},
  networkQualities: {},
};

const batching = getFeatureQueryParam('redux-auto-batching');

export const participantSlice = createAppSlice({
  name: 'participant',
  initialState,

  reducers: (create) => ({
    setVenueId: create.reducer(
      (state, action: PayloadAction<string | null>) => {
        state.venueId = action.payload;
      }
    ),

    setMyClientId: create.reducer(
      (state, action: PayloadAction<string | null>) => {
        state.myClientId = action.payload;
      }
    ),

    setParticipants: create.reducer(
      (state, action: PayloadAction<ParticipantFullMap>) => {
        state.fulls = {};
        state.flags = {};
        state.skims = {};

        for (const p of Object.values(action.payload)) {
          if (!p) continue;
          state.fulls[p.clientId] = p;
          state.flags[p.clientId] = intoParticipantFlags(p);
          state.skims[p.clientId] = intoParticipantSkim(p);
        }
      }
    ),

    addParticipant: create.reducer(
      (state, action: PayloadAction<ParticipantFull>) => {
        rsIncrement('participant-add-c');
        const p = action.payload;
        state.fulls[p.clientId] = p;
        state.flags[p.clientId] = intoParticipantFlags(p);
        state.skims[p.clientId] = intoParticipantSkim(p);
      }
    ),

    addParticipants: create.preparedReducer(
      batching
        ? prepareAutoBatched<ParticipantFull[]>()
        : (payload: ParticipantFull[]) => ({ payload }),
      (state, action) => {
        for (let i = 0; i < action.payload.length; i++) {
          rsIncrement('participant-update-c'); // only called on addedOrChanged
          const p = action.payload[i];
          safelyAssignParticipant(state, p);
        }
      }
    ),

    updateParticipant: create.reducer(
      (state, action: PayloadAction<Partial<ParticipantFull>>) => {
        const { clientId } = action.payload;
        if (!clientId) return;

        const full = state.fulls[clientId];
        const flags = state.flags[clientId];
        const skim = state.skims[clientId];
        if (!full || !flags || !skim) return;

        rsIncrement('participant-update-c');

        const updatedParticipant = action.payload;
        if (updatedParticipant.joinedAt) delete updatedParticipant.joinedAt;

        // Taking care to assign the existing object if it exists, and only
        // touching specific properties.

        state.fulls[clientId] = Object.assign(
          state.fulls[clientId] ?? {},
          full,
          updatedParticipant
        );

        state.flags[clientId] = Object.assign(
          state.flags[clientId] ?? {},
          flags,
          intoParticipantFlags(updatedParticipant)
        );

        state.skims[clientId] = Object.assign(
          state.skims[clientId] ?? {},
          skim,
          intoParticipantSkim(updatedParticipant)
        );
      }
    ),

    removeParticipant: create.reducer(
      (state, action: PayloadAction<string>) => {
        rsIncrement('participant-remove-c');
        const clientId = action.payload;
        delete state.fulls[clientId];
        delete state.flags[clientId];
        delete state.skims[clientId];
        delete state.networkQualities[clientId];
      }
    ),

    removeParticipants: create.reducer(
      (state, action: PayloadAction<string[]>) => {
        for (let i = 0; i < action.payload.length; i++) {
          rsIncrement('participant-remove-c');
          const clientId = action.payload[i];
          delete state.fulls[clientId];
          delete state.flags[clientId];
          delete state.skims[clientId];
          delete state.networkQualities[clientId];
        }
      }
    ),

    setNetworkQuality: create.reducer(
      (state, action: PayloadAction<ParticipantNetworkQuality>) => {
        state.networkQualities[action.payload.clientId] = Object.assign(
          state.networkQualities[action.payload.clientId] ?? {},
          action.payload
        );
      }
    ),

    setNetworkQualities: create.reducer(
      (state, action: PayloadAction<ParticipantNetworkQuality[]>) => {
        for (let i = 0; i < action.payload.length; i++) {
          const payload = action.payload[i];
          state.networkQualities[payload.clientId] = Object.assign(
            state.networkQualities[payload.clientId] ?? {},
            payload
          );
        }
      }
    ),

    reset: create.reducer((state) => {
      Object.keys(initialState).forEach((key) => {
        uncheckedIndexAccess_UNSAFE(state)[key] =
          uncheckedIndexAccess_UNSAFE(initialState)[key];
      });
    }),
  }),
});

export function selectLastJoined(
  candidates: Participant[],
  excludedDisconnected = true
): Participant | null {
  if (candidates.length === 0) return null;
  const sorted = candidates.sort((a, b) => b.joinedAt - a.joinedAt);
  const active = candidates.find(
    (p) => p.status === ConnectionStatus.Connected
  );
  if (active) return active;

  const lastJoined = sorted[0];
  if (excludedDisconnected) {
    if (
      lastJoined.status === ConnectionStatus.Disconnected &&
      lastJoined.disconnectedAt &&
      Date.now() - lastJoined.disconnectedAt < CONFIG.recoveryTimeoutMs
    ) {
      return lastJoined;
    }
  } else {
    return lastJoined ?? null;
  }

  return null;
}

export function isHeartbeatExpired(lastHeartbeatAt: number | undefined) {
  if (lastHeartbeatAt === undefined) return false;
  return (
    Clock.instance().now() - lastHeartbeatAt > CONFIG.heartbeatInternal * 2
  );
}

export const isActiveParticipant = (m: Participant | null): boolean =>
  !!m?.clientId && m.status === ConnectionStatus.Connected;

export const participantSliceReducer = participantSlice.reducer;
